/*
Component: <exceed-carousel>

For an image carousel. Wraps the Glide js carousel library.
https://github.com/glidejs/glide
https://glidejs.com/docs/

Adds several a11y needs to the library component, since the library has dubiously declined to (https://github.com/glidejs/glide/pull/347), though it does enable
keyboard functionality.

Usage notes:
- Give the <exceed-carousel> element `class="carousel"` and `aria-hidden="true"`.
The aria-hidden will be removed once the js component has mounted.
*/

import { PolymerElement } from '@polymer/polymer';
import Glide, { Autoplay, Controls, Keyboard, Swipe } from '@glidejs/glide/dist/glide.modular.esm';

// This provides a default for the component; it is recommended that you replace
// this by passing the ariaCurrentMessage property with a value including
// the actual slide count, because the component adds clone items to the slide list
let ariaCurrentMessage = '"Item {{POSITION}} of {{TOTAL ITEMS}}"';

if (window.Intellum && window.Intellum.i18nStrings) {
  ariaCurrentMessage = window.Intellum.i18nStrings.showing_item_position_of_total;
}

class ExceedCarousel extends PolymerElement {
  static get is() {
    return 'exceed-carousel';
  }

  static get properties() {
    return {
      dir: {
        // rtl if applicable
        type: String,
        value: ''
      },
      autoplayInterval: {
        // interval in milliseconds that slides change during autoplay;
        // 0 to turn off autoplay
        type: Number,
        value: 0
      },
      gapWidth: {
        // in pixels, gap between slides
        type: Number,
        value: 0
      },
      sliverWidth: {
        // in pixels, how much of the prev or next slide shows on the side
        type: Number,
        value: 0
      },
      animationDuration: {
        // length in milliseconds of transition when slides change
        type: Number,
        value: 500
      },
      ariaCurrentMessage: {
        // A string that we "read" when the carousel moves (with {{POSITION}} and {{TOTAL ITEMS}} being replaced)
        type: String,
        value: ariaCurrentMessage
      },
      a11yAnnouncementDivId: {
        // id of div in the component used to announce carousel change to screen readers
        type: String,
        value: 'carousel-announcements'
      }
    }
  }


  /* Recurring function */

  hideClones() {
    // Ensure that item clones before and after carousel list have aria-hidden="true"
    this.querySelectorAll('.carousel__listitem--clone').forEach((clone) => {
      clone.setAttribute('aria-hidden', 'true');
    });
  }


  /* Observer callbacks */

  handleItemChange() {
    let currentItem = this._itemsList.querySelector('.carousel__listitem--active');
    currentItem?.removeAttribute('aria-hidden');

    // Only change tabindex if item change follows navigation click or key, based on _navigationUsed flag
    if (this._navItems && this._isNavigationUsed) {
      // Clear any set tabindex
      this._navItems.forEach((navItem) => {
        navItem.removeAttribute('tabindex');
      });

      // Add tabindex="-1" to selected image to enable focus
      currentItem.setAttribute('tabindex', '-1');
      // Focus only if keyboard was used
      if (this._isNavigationKeyUsed) {
        currentItem.focus();
      }

      this._isNavigationUsed = false;
      this._isNavigationKeyUsed = false;
    }
  }

  handleNavButtonChange() {
    this._navButtons.forEach((navItem) => {
      navItem.removeAttribute('aria-current');
    });

    // Add aria-current to nav for current item
    let currentNavItem = this._navButtonList.querySelector('.carousel__navitem--active');
    currentNavItem?.setAttribute('aria-current', 'location');
  }


  /* Function passed to Glide object */

  setAutoplayOption() {
    return (this.autoplayInterval == 0) ? false : this.autoplayInterval;
  }


  /* Event handlers set in init and bindHandlers */

  handleCarouselMounted() {
    // Hide all images from screen readers initially
    if (this._items) {
      this._items.forEach((item) => {
        item.setAttribute('aria-hidden', 'true');
      });
    }

    // Reveal the mounted carousel to screen readers
    this.removeAttribute('aria-hidden');

    this.hideClones();

    // Display carousel controls
    this.querySelector('.carousel__arrows').classList.add('carousel__arrows--visible');
    this.querySelector('.carousel__nav').classList.add('carousel__nav--visible');
  }

  handleCarouselBeginMove() {
    // Hide item we're replacing
    let currentItem = this.querySelector('.carousel__listitem--active');
    if (currentItem) {
      currentItem.setAttribute('aria-hidden', 'true');
    }
  }

  handleCarouselMoved() {
    // Announce change to screen readers
    if (this._announcementDiv) {
      this._announcementDiv.innerText = this.ariaCurrentMessage
        .replace('{{POSITION}}', this._glide.index + 1)
        .replace('{{TOTAL ITEMS}}', this.querySelector('.carousel__list').children.length - 2); // accounts for clone items
    }

    this.hideClones();
  }

  handleNavigationClick() {
    this._isNavigationUsed = true;
  }

  handleNavigationKey(event) {
    // Make arrow keys effective only when keyboard user is "in" the carousel
    // Also make use of enter key on nav item set focus
    if (this.contains(document.activeElement) && (event.code === 'ArrowLeft' || event.code == "ArrowRight" || (event.code === 'Enter' && this._navItems.includes(document.activeElement.closest('li'))))) {
      this._isNavigationUsed = true;
      this._isNavigationKeyUsed = true;
    }
  }

  handlePausePlayButton() {
    if (this.autoplayInterval > 0 && this._pausePlayButton) {
      if (this._isAutoplayPaused) {
        /*
          Because we're using the option to stop autoplay when hovering over
          the carousel, even if we #stop Autoplay, it will resume once the mouse
          leaves the carousel. You have to both #unbind and #stop Autoplay,
          then to resume, #bind and #start.
         */
        this._glide._c.Autoplay.bind();
        this._glide._c.Autoplay.start();
      } else {
        this._glide._c.Autoplay.unbind();
        this._glide._c.Autoplay.stop();
      }

      // Switch content
      this._pausePlayButton.querySelectorAll('.carousel__pauseplaycontent').forEach((content) => {
        if (content.classList.contains('carousel__pauseplaycontent--active')) {
          content.classList.remove('carousel__pauseplaycontent--active');
        } else {
          content.classList.add('carousel__pauseplaycontent--active');
        }
      });

      this._isAutoplayPaused = !this._isAutoplayPaused;
    }
  }


  /* bind event handlers and observers to DOM */

  bindEventHandlers() {
    this._boundNavigationClickHandler = this.handleNavigationClick.bind(this);
    this._boundNavigationKeyHandler = this.handleNavigationKey.bind(this);
    this._boundItemChangeHandler = this.handleItemChange.bind(this);
    this._boundNavButtonChangeHandler = this.handleNavButtonChange.bind(this);
    this._boundPausePlayButtonHandler = this.handlePausePlayButton.bind(this);

    if (this._navItems) {
      this._navItems.forEach((navItem) => {
        navItem.addEventListener('click', this._boundNavigationClickHandler);
      });
    }

    // Bind arrow keys for navigation, using keyup because that's what Glide does
    document.addEventListener('keyup', this._boundNavigationKeyHandler);

    // Bind pause/play button
    this._pausePlayButton = this.querySelector('.carousel__pauseplay');
    if (this._pausePlayButton && this.autoplayInterval > 0) {
      this._pausePlayButton.addEventListener('click', this._boundPausePlayButtonHandler);
    }
    // Bind observers
    this._itemChangeObserver = new MutationObserver(this._boundItemChangeHandler);
    this._itemChangeObserver.observe(this._itemsList, {attributeFilter: ['class'], subtree: true});

    if (this._navButtons) {
      this._navButtonChangeObserver = new MutationObserver(this._boundNavButtonChangeHandler);
      this._navButtonChangeObserver.observe(this._navButtonList, {attributeFilter: ['class'], subtree: true});
    }
  }

  unbindEventHandlers() {
    if (this._navItems) {
      this._navItems.forEach((navItem) => {
        navItem.removeEventListener('click', this._boundNavigationClickHandler);
      });
    }

    document.removeEventListener('keyup', this._boundNavigationKeyHandler);

    if (this._pausePlayButton && this.autoplayInterval > 0) {
      this._pausePlayButton.removeEventListener('click', this._boundPausePlayButtonHandler);
    }

    // Unbind observers
    this._itemChangeObserver.disconnect();
    if (this._navButtons) {
      this._navButtonChangeObserver.disconnect();
    }
  }


  /* init */

  init() {
    this._itemsList = this.querySelector('.carousel__list');
    this._items = this._itemsList.children;
    if (this._items) {
      this._items = Array.prototype.slice.call(this._items);
    } else {
      return;
    }

    let navArrows = Array.prototype.slice.call(this.querySelector('.carousel__arrows').children);
    this._navButtonList = this.querySelector('.carousel__nav');
    this._navButtons = Array.prototype.slice.call(this._navButtonList.children);
    this._navItems = navArrows.concat(this._navButtons);

    this._announcementDiv = document.getElementById(this.a11yAnnouncementDivId);
    this._isNavigationUsed = false;
    this._isNavigationKeyUsed = false;
    this._isAutoplayPaused = false;

    this._glide = new Glide('.carousel', {
      type: 'carousel',
      focusAt: 'center',
      autoplay: this.setAutoplayOption(),
      gap: this.gapWidth,
      peek: this.sliverWidth,
      animationDuration: this.animationDuration,
      classes: {
        direction: {
          ltr: 'carousel--ltr',
          rtl: 'carousel--rtl'
        },
        cloneSlide: 'carousel__listitem--clone',
        activeSlide: 'carousel__listitem--active',
        activeNav: 'carousel__navitem--active',
        disabledArrow: 'carousel__arrow--disabled'
      }
    });

    if (this.dir == 'rtl') {
      this._glide.update({direction: 'rtl'});
    }

    this._boundCarouselMountedHandler = this.handleCarouselMounted.bind(this);
    this._boundCarouselBeginMoveHandler = this.handleCarouselBeginMove.bind(this);
    this._boundCarouselMovedHandler = this.handleCarouselMoved.bind(this);
    this._glide.on('mount.after', this._boundCarouselMountedHandler);
    this._glide.on('move', this._boundCarouselBeginMoveHandler);
    this._glide.on('move.after', this._boundCarouselMovedHandler);

    this._glide.mount({ Autoplay, Controls, Keyboard, Swipe });
  }

  connectedCallback() {
    super.connectedCallback();
    this.init();
    this.bindEventHandlers();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.unbindEventHandlers();
  }
}

customElements.define('exceed-carousel', ExceedCarousel);
