/*
Component: <exceed-nav-text>
Encloses the main .appnav__list in the learner view if text is being used
instead of icons, so that all items fit in the heading regardless of viewport width,
with extra items placed in the submenu.
Initially and on resize, it moves items between the main menu and the submenu as needed.

The operation of this component is heavily dependent on the presentation of the
html element.

This element needs this css, which is in exceed-nav-text.scss: `display: inline-block; width: auto; max-width: 100%;`
The parent of this element needs this css: `display: block; width: 100%;`
The list that is a child of this element needs `display: flex;`

This outlines the rendered html, including classes that achieve the required css. The first list item shows current treatment.

```
<nav class="appnav appnav--text">
  <exceed-nav-text>
    <ul class="appnav__list">
      <li class="appnav__listitem appnav__listitem--text">
        <a class="appnav__link appnav__link--text u-org--header appnav__link--current u-orgcolor--borderonly" href="link">Text</a>
      </li>
      <li class="appnav__listitem appnav__listitem--text">
        <a class="appnav__link appnav__link--text u-org--header u-orgcolor--borderhover" href="link">Text</a>
      </li>

      <!-- More links up to max allowed - 1, or all of them if total is under limit -->

      <!-- menu button and list -->
      <li class="appnav__listitem appnav__listitem--navmenu">
        <exceed-nav-menu class="navmenu">
          <button name="button" type="button" role="button" class="appnav__link appnav__link--more navmenu__trigger u-org--header" aria-haspopup="true" aria-expanded="false" aria-label="More"><!-- svg --></button>
          <ul class="appnav__sublist navmenu__menu">
            <li class="appnav__sublistitem navmenu__menuitem"><a class="appnav__sublink" href="link">Text</a></li>
            <li class="appnav__sublistitem navmenu__menuitem"><a class="appnav__sublink" href="link">Text</a></li>
          </ul>
        </exceed-nav-menu>
      </li>
    </ul>
  </exceed-nav-text>
</nav>
```

The submenu must exist, with its parent `<li>` hidden if it's empty (because the number of total items is below the max).
*/

import { PolymerElement } from '@polymer/polymer';
import debounce from '../util/debounce';

export default class ExceedNavText extends PolymerElement {

  static get is() {
    return 'exceed-nav-text';
  }

  static get properties() {
    return {
      listElSelector: {
        // selector for the main nav
        type: String,
        value: '.appnav__list'
      },
      listitemElSelector: {
        // selector for each nav item (but not the submenu)
        type: String,
        value: '.appnav__listitem--text'
      },
      listitemMenuElSelector: {
        // selector for the listitem that contains the submenu
        type: String,
        value: '.appnav__listitem--navmenu'
      },
      menuElSelector: {
        // selector for the submenu
        type: String,
        value: '.navmenu__menu'
      },
      menuElContainerSelector: {
        // selector for the submenu ancestor that is hidden or not
        type: String,
        value: '.appnav__listitem:not(.appnav__listitem--text)'
      },
      listitemElClasses: {
        // classes for a main menu item
        type: Array,
        value: ["appnav__listitem", "appnav__listitem--text"]
      },
      listitemLinkClasses: {
        // classes for a main menu link
        type: Array,
        value: ["appnav__link", "appnav__link--text", "u-org--header", "u-orgcolor--borderhover"]
       },
      listitemCurrentLinkClasses: {
        // classes for a main menu current link
        type: Array,
        value: ["appnav__link", "appnav__link--text", "u-org--header", "appnav__link--current", "u-orgcolor--borderonly"]
       },
      menuListitemElClasses: {
        // classes for a main menu item link
        type: Array,
        value: ["appnav__sublistitem", "navmenu__menuitem"]
       },
      menuListitemLinkClasses: {
        // classes for a main menu link
        type: Array,
        value: ["appnav__sublink"]
       },
      menuListitemCurrentLinkClasses: {
        // classes for a main menu current link
        type: Array,
        value: ["appnav__sublink", "appnav__sublink--current"]
      },
      currentListClassModifier: {
        // substring that will match class value for current item
        type: String,
        value: '--current'
      }
    }
  }

  /* Determine whether nav items are past max length initially (menu is hidden) */
  getMaxMenuLength() {
    return (this._menuElContainer.hasAttribute('hidden')) ? 0 : this._listitemEls.length;
  }

  /* Move an item from the main nav to the submenu */
  doFillMenu(navItems) {
    // If nav is too wide, move the last item to the submenu
    let itemToMove = navItems[navItems.length - 1];
    let itemToMoveLink = itemToMove.querySelector('a');
    let itemIsCurrent = itemToMoveLink.getAttribute('class').includes(this.currentListClassModifier);

   // Remove old classes from listitem and link
    itemToMove.classList.remove(...itemToMove.classList);
    itemToMoveLink.classList.remove(...itemToMoveLink.classList);
    // add new classes to both
    itemToMove.classList.add(...this.menuListitemElClasses);
    let navItemMenuLinkElClasses = (itemIsCurrent) ? this.menuListitemCurrentLinkClasses : this.menuListitemLinkClasses;
    itemToMoveLink.classList.add(...navItemMenuLinkElClasses);

    // Make sure submenu is visible
    this._menuElContainer.removeAttribute('hidden');

    // Add it to the beginning of the submenu
    this._menuEl.prepend(itemToMove);

    // Check this all another time to see if it's still too wide
    //this.adjustNavList('menu');
  }

  /* Move an item from the submenu to the main nav (if it fits) */
  doFillMain(navItems, menuItems, navParentEl, navMaxWidth) {
    if (menuItems.length) {
      // Unless we're already at max number of items
      if (!(this._maxItems != 0 && navItems.length >= this._maxItems)) {
        // See if there's room to move a submenu item to the main nav
        let itemToMove = menuItems[0];
        let itemToMoveLink = itemToMove.querySelector('a');
        let itemIsCurrent = itemToMoveLink.getAttribute('class').includes(this.currentListClassModifier);

        // Provisionally add the item to the mainnav
        let navItemEl = itemToMove.cloneNode(true);
        let navItemLinkEl = navItemEl.querySelector('a');

        // Remove old classes from provisional listitem and link
        navItemEl.classList.remove(...navItemEl.classList);
        navItemLinkEl.classList.remove(...navItemLinkEl.classList);

        // Add new classes
        navItemEl.classList.add(...this.listitemElClasses);
        let navItemLinkElClasses = (itemIsCurrent) ? this.listitemCurrentLinkClasses : this.listitemLinkClasses;
        navItemLinkEl.classList.add(...navItemLinkElClasses);

        // Keep the item out of view a moment while we add it
        navItemEl.style.opacity = '0';
        this._listEl.insertBefore(navItemEl, this._menuElContainer);

        // See if the nav is too wide now
        // Temporarily change the parent oveflow and hide the submenu to do this
        navParentEl.style.overflow = 'hidden';
        this._menuEl.style.display = 'none';
        let tempNavWidth = this.scrollWidth;
        navParentEl.style.overflow = '';
        this._menuEl.style.display = 'block';

        if (tempNavWidth > navMaxWidth) {
          // Cancel the item move
          this._listEl.removeChild(navItemEl);
        } else {
          // Make the moved item visible
          navItemEl.style.opacity = '1';

          // Remove the original item from the menu
          this._menuEl.removeChild(itemToMove);

          // Hide the menu if it's empty
          if (!this._menuEl.querySelectorAll('li').length) {
            this._menuElContainer.setAttribute('hidden', true);
          }
          // See if we can still move another item based on width
          this.adjustNavList('main');
        }
      }
    }
  }

  /* Move items between main nav and submenu as needed depending on width */
  adjustNavList(areaBeingFilled = null) {
    let navItems = this._listEl.querySelectorAll(this.listitemElSelector);
    let menuItems = this._menuEl.querySelectorAll('li');

    // Get the nav width and the max nav width
    let navParentEl = this.parentElement;

    // Temporarily change the parent oveflow and hide the submenu to do this
    navParentEl.style.overflow = 'hidden';
    this._menuEl.style.display = 'none';
    let navWidth = this.scrollWidth;
    let navMaxWidth = navParentEl.clientWidth;
    navParentEl.style.overflow = '';
    this._menuEl.style.display = 'block';

    // If this element is wider that the parent, move things to the submenu
    if (navWidth > navMaxWidth) {
      // If we're already trying to fill the main menu, don't try to fill the submenu
      if (areaBeingFilled != 'menu') {
        this.doFillMenu(navItems);
      }

    // If this element is narrower than the parent, try to move things from submenu to main
    } else if (navWidth < navMaxWidth) {
      // If we're already trying to fill the submenu, don't try to fill the main menu
      if (areaBeingFilled != 'main') {
        this.doFillMain(navItems, menuItems, navParentEl, navMaxWidth);
      }
    }
  }

  /* Update nav on resize */
  handleWindowResize() {
    window.addEventListener('resize', debounce(() => {
      this.adjustNavList();
    }, 250));
  }

  init() {
    this._listEl = this.querySelector(this.listElSelector);
    this._listitemEls = this.querySelectorAll(this.listitemElSelector);

    // The submenu should exist for text nav even if it's empty
    this._menuEl = this.querySelector(this.menuElSelector);

    if (this._listEl && this._listitemEls.length && this._menuEl) {
      this._listitemMenuEl = this.querySelector(this.listitemMenuElSelector);
      this._menuElContainer = this.querySelector(this.menuElContainerSelector);
      this._maxItems = this.getMaxMenuLength();

      this.adjustNavList();
      this.handleWindowResize();
    }
  }

  connectedCallback() {
    super.connectedCallback();
    this.init();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }
}

customElements.define('exceed-nav-text', ExceedNavText);
