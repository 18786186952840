/*
Component: <exceed-notifications-subscribe>
Usage: This component subscribes to user mentor card updates through
       Centrifugo's websocket.
       
       Once a notification is received, it tries to request notifications permission.
       If it was "granted" or "denied" it responds immediately. Otherwise it will prompt
       the user for permission. If permission is granted then it shows the notification.

       When the notification is clicked the behaviour depends on whether the notification
       has an associated url or not. If it has an url, that url is open in a new tab. If
       it doesn't have a url, it focuses the browser tab that originated the notification
       and opens the user mentor cards drawer.

       When a notification is closed (by clicking or by closing it) it marks that notification
       as read.
*/

import PubSub from 'pubsub-js';
import pubSubEvents from '../util/pubsub_event_channels';
import centrifugo from '../util/centrifugo';
import notifications from '../util/browser_notifications';
import { PolymerElement } from '@polymer/polymer';

export default class ExceedNotificationsSubscribe extends PolymerElement {

  static get is() {
    return 'exceed-notifications-subscribe';
  }

  static get properties() {
    return {
      // Centrifugo channel name to subscribe to
      channel: {
        type: String
      },
      // Id of the user mentor cards drawer
      notificationsDrawerElId: {
        type: String,
        value: 'updatedrawer-main'
      }
    }
  }

  changeNotificationsBadge(value) {
    document.dispatchEvent(new window.CustomEvent('notifications.unreadNumberChange', { detail: { type: "increment", value: value } }));
  }

  forceNotificationsDrawerReload() {
    if (this._updateDrawer) {
      this._updateDrawer.resetOnUnreadNumberChange();
    }
  }

  openNotificationsDrawer() {
    if (this._updateDrawer) {
      window.focus();
      this._updateDrawer.openUpdatedrawer();
    }
  }

  openNewTab(url) {
    window.open(url, "_blank");
  }

  runAction(attrs) {
    if (attrs.url && attrs.url != "") {
      this.openNewTab(attrs.url);
    } else {
      this.openNotificationsDrawer();
    }
  }

  markAsRead(attrs) {
    if (this._updateDrawer && attrs.user_mentor_card_id) {
      this._updateDrawer.postMarkOneRead(attrs.user_mentor_card_id);
    }
  }

  notify(attrs) {
    attrs['tag'] = `userMentorCard-${attrs.user_mentor_card_id}`;
    var notification = this.notifications.create(attrs);

    notification.onclick = function() {
      this.runAction(attrs);
      notification.close();
    }.bind(this);

    notification.onclose = function() {
      this.markAsRead(attrs)
      this.changeNotificationsBadge(-1);
      this.forceNotificationsDrawerReload();
    }.bind(this);
  }

  handleNotification(message) {
    this.changeNotificationsBadge(1);
    this.forceNotificationsDrawerReload();

    this.notifications.requestPermission(function() {
      this.notify(message.data);
    }.bind(this));
  }

  init() {
    if (this.notifications.isSupported() && this.centrifugo && !this._subscription) {
      if (this.notificationsDrawerElId && window.Intellum && window.Intellum.updatedrawers) {
        this._updateDrawer = window.Intellum.updatedrawers[this.notificationsDrawerElId];
      }
      this._subscription = this.centrifugo.subscribe(this.channel, this.handleNotification.bind(this));
    }
  }

  connectedCallback() {
    super.connectedCallback();
    this.centrifugo = centrifugo;
    this.notifications = notifications;
    this.init();
  }

}

customElements.define('exceed-notifications-subscribe', ExceedNotificationsSubscribe);
