/*
Component: <exceed-hero-video>

For an video used as a hero "image." Needs to be wrapped around an html `<video />` element & pause/play buttons for accessibility.

Example:

```
<exceed-hero-video class="herovideo">
  <video autoplay muted loop>
    <source src="video.mp4" type="video/mp4">
  </video>
  <div class="herovideo__controls">
    <button type="button" class="herovideo__pauseplay" aria-label="Pause" data-behavior="pause">
      Pause
    </button>
    <button type="button" class="herovideo__pauseplay herovideo__pauseplay--hidden" aria-label="Play" data-behavior="play">
      Play
    </button>
  </div>
</exceed-hero-video>
```
*/

import { PolymerElement } from '@polymer/polymer';


class ExceedHeroVideo extends PolymerElement {
  static get is() {
    return 'exceed-hero-video';
  }

  static get properties() {
    return {
      pausePlaySelector: {
        // selector for each pause/play button
        type: String,
        value: '.herovideo__pauseplay'
      },
      pausePlayHiddenClass: {
        // class to add to the pause/play control that is not being shown
        type: String,
        value: 'herovideo__pauseplay--hidden'
      }
    }
  }

  handlePausePlayButton(event) {
    // This does not assume that video has autoplay or loops
    let isPaused = this._video.paused || this._video.ended;
    (isPaused) ? this._video.play() : this._video.pause();
    isPaused = !isPaused;

    this._pausePlayButtons.forEach((button) => {
      if (isPaused) {
        if (button.getAttribute('data-behavior') == 'play') {
          button.classList.remove(this.pausePlayHiddenClass);
          button.focus();
        } else {
          button.classList.add(this.pausePlayHiddenClass);
        }
      } else {
        if (button.getAttribute('data-behavior') == 'pause') {
          button.classList.remove(this.pausePlayHiddenClass);
          button.focus();
        } else {
          button.classList.add(this.pausePlayHiddenClass);
        }
      }
    });
  }

  bindEventHandlers() {
    this._boundPausePlayButtonHandler = this.handlePausePlayButton.bind(this);
    this._pausePlayButtons = this.querySelectorAll(this.pausePlaySelector);
    if (this._pausePlayButtons) {
      this._pausePlayButtons.forEach((button) => {
        button.addEventListener('click', this._boundPausePlayButtonHandler);
      });
    }
  }

  unbindEventHandlers() {
    if (this._pausePlayButtons) {
      this._pausePlayButtons.forEach((button) => {
        button.removeEventListener('click', this._boundPausePlayButtonHandler);
      });
    }
  }

  init() {
    this._video = this.querySelector('video');
    if (!this._video) {
      return;
    } else {
      this.bindEventHandlers();
    }
  }

  connectedCallback() {
    super.connectedCallback();
    this.init();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.unbindEventHandlers();
  }
}

customElements.define('exceed-hero-video', ExceedHeroVideo);
