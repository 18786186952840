/*
Component: <exceed-notifications-control>
Usage: This component is meant to request notifications permission and give feedback
       to the user about the current notifications permission state.

       Notifications permission (window.Notification.permission) can have 3 values:
       - "default": We didn't request notifications permission yet
       - "granted": We requested permission and it was granted
       - "denied": We requested permission and it was denied

       This component shows and hides elements depending on what is the current state
       of notifications permission.

       The element shown when the permission is "default" may include an element that,
       when clicked, requests notifications permission.
*/

import PubSub from 'pubsub-js';
import pubSubEvents from '../util/pubsub_event_channels';
import notifications from '../util/browser_notifications';
import { PolymerElement } from '@polymer/polymer';

export default class ExceedNotificationsControl extends PolymerElement {

  static get is() {
    return 'exceed-notifications-control';
  }

  static get properties() {
    return {
      // This is the element where the user has to click to enable browser notifications.
      enableNotificationsElId: {
        type: String,
        value: ''
      },
      // This element is shown when we didn't request notifications permission yet.
      defaultElId: {
        type: String,
        value: ''
      },
      // This element is shown when notifications permission is granted.
      grantedElId: {
        type: String,
        value: ''
      },
      // This element is shown when notifications permission is denied.
      deniedElId: {
        type: String,
        value: ''
      },
      // This message (if present) will be shown to the user if he/she clicks
      // the "enable notifications" element, and when the browser asks for permission
      // the user grants it
      permissionGrantedMessage: {
        type: String,
        value: ''
      },
      // This message (if present) will be shown to the user if he/she clicks
      // the "enable notifications" element, but when the browser asks for permission
      // the user denies it.
      permissionDeniedMessage: {
        type: String,
        value: ''
      }
    };
  }

  maybeShow(el) {
    if (el) { el.classList.remove('hide'); }
  }

  maybeHide(el) {
    if (el) { el.classList.add('hide'); }
  }

  updateState() {
    var permission = this.notifications.permission();

    if (permission === "default") {
      this.maybeShow(this._defaultEl);
      this.maybeHide(this._grantedEl);
      this.maybeHide(this._deniedEl);
    } else if (permission === "granted") {
      this.maybeHide(this._defaultEl);
      this.maybeShow(this._grantedEl);
      this.maybeHide(this._deniedEl);
    } else if (permission === "denied") {
      this.maybeHide(this._defaultEl);
      this.maybeHide(this._grantedEl);
      this.maybeShow(this._deniedEl);
    }
  }

  alertPermissionGranted() {
    if (this.permissionGrantedMessage && window.Intellum && window.Intellum.flashnotice && this.permissionDeniedMessage !== '') {
      Intellum.flashnotice.show(this.permissionGrantedMessage);
    }
  }

  alertPermissionDenied() {
    if (this.permissionDeniedMessage) {
      if (window.Intellum && window.Intellum.flashnotice && this.permissionDeniedMessage !== '') {
        Intellum.flashnotice.show(this.permissionDeniedMessage);
      } else {
        alert(this.permissionDeniedMessage);
      }
    }
  }

  enableNotifications(event) {
    this.notifications.requestPermission(
      this.alertPermissionGranted.bind(this),
      this.alertPermissionDenied.bind(this)
    );
    this.dispatchEvent(new CustomEvent('navmenu.hide', {bubbles: true}));
    event.preventDefault();
  }

  bindEnableNotificationsButton() {
    if (!this._boundEnableNotifications) {
      var el = document.getElementById(this.enableNotificationsElId);
      if (el) {
        this._boundEnableNotifications = this.enableNotifications.bind(this);
        this.addEventListener('click', this._boundEnableNotifications);
      }
    }
  }

  init() {
    if (this.notifications.isSupported()) {
      this._defaultEl = document.getElementById(this.defaultElId);
      this._grantedEl = document.getElementById(this.grantedElId);
      this._deniedEl = document.getElementById(this.deniedElId);

      PubSub.subscribe(pubSubEvents.notifications_change, this.updateState.bind(this));
      this.updateState();

      this.bindEnableNotificationsButton();
      this.maybeShow(this);
    } else {
      this.maybeHide(this);
    }
  }

  connectedCallback() {
    super.connectedCallback();
    this.notifications = notifications;
    this.init();
  }

}

customElements.define('exceed-notifications-control', ExceedNotificationsControl);
