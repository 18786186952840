/*
Component: <exceed-centrifugo-connection-test>
Usage: This component is meant to be used for testing only. It forces opening a websocket connection
       against Centrifugo.
       In production websocket connection will only be established if there is some channel to
       subscribe to.
*/

import centrifugo from '../util/centrifugo';
import { PolymerElement } from '@polymer/polymer';

class ExceedCentrifugoConnectionTest extends PolymerElement {
  static get is() {
    return 'exceed-centrifugo-connection-test';
  }

  static get properties() {
    return {}
  }

  init() {
    if (this.centrifugo) {
      this.centrifugo.connect();
    }
  }

  connectedCallback() {
    super.connectedCallback();
    this.centrifugo = centrifugo;
    this.init();
  }
}

customElements.define('exceed-centrifugo-connection-test', ExceedCentrifugoConnectionTest);
