/*
Component: <exceed-additional-plan-quarters>
Usage:
Notes:
*/

import { PolymerElement } from '@polymer/polymer';
import { parseISO, addMonths, format } from 'date-fns';
import PubSub from "pubsub-js";
import pubSubEvents from "../util/pubsub_event_channels";

class ExceedAdditionalPlanQuarters extends PolymerElement {

  static get is() {
    return 'exceed-additional-plan-quarters';
  }

  static get properties() {
    return {
      lastQuarter: {
        type: String,
        value: ''
      },
      lastQuarterAsDate: { 
        type: Date,
        computed: 'convertToDate(lastQuarter)'
      },
      additionalPlanClass: {
        type: String,
        value: 'activitylistgroup--additional'
      },
      quarterSelector: {
        type: String,
        value: '.activitylistgroup'
      }
    }
  }

  convertToDate(date) {
    return parseISO(date)
  }

  showNext() {
    let nextQuarterAsDate = addMonths(this.lastQuarterAsDate, 3);
    let nextQuarter = format(nextQuarterAsDate, 'yyyy-MM-dd');
    let dataQuarter = this.querySelector(`${this.quarterSelector}[data-quarter='${nextQuarter}']`);
    if (dataQuarter) {
      dataQuarter.classList.remove(this.additionalPlanClass);
    }
    this.lastQuarter = nextQuarter;
    this.numberOfAdditionalSections = this.numberOfAdditionalSections - 1;
    if (!this.numberOfAdditionalSections) {
      this.buttonEl.parentNode.removeChild(this.buttonEl);
    }
  }

  bindToSortableMove() {
    this._sortableBinding = PubSub.subscribe(pubSubEvents.sortable, (msg, eventDetail) => {
      // If an item has been moved to the end of the last open list by keyboard,
      // go ahead and reveal the next list if it's hidden
      if ((msg == pubSubEvents.sortable_success || msg == pubSubEvents.sortable_choose) && eventDetail && eventDetail.isKeyboardMove && eventDetail.itemId && eventDetail.toList) {
        if (eventDetail.toList.lastElementChild == document.getElementById(eventDetail.itemId)) {
          let thisSection = eventDetail.toList.closest('.activitylistgroup');
          if (thisSection && thisSection.nextElementSibling && (thisSection.nextElementSibling == this || thisSection.nextElementSibling.classList.contains('activitylistgroup--additional'))) {
            this.showNext();
          }
        }
      }
    });
  }

  connectedCallback() {
    super.connectedCallback();
    this.numberOfAdditionalSections = this.querySelectorAll(this.quarterSelector).length;
    this.buttonEl.addEventListener('click', () => {
      this.showNext();
    });
    this.bindToSortableMove();
  }

  disconnectedCallback() {
    if (this._sortableBinding) {
      PubSub.unsubscribe(this._sortableBinding);
    }
  }

  constructor() {
    super();
    this.buttonEl = this.querySelector('button');
  }

}

customElements.define('exceed-additional-plan-quarters', ExceedAdditionalPlanQuarters);
