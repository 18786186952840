/*
Component: <exceed-filter-counter>

Part of the `exceed-filter-*` ecosystem. This component manages the content
of a counter when the filter content has updated.

Optional: add the property `text-property` to specify which value to use
when it might receive multiple text values. The value of this property
should be the property that gets passed in the PubSub data for the wanted value.

Example: Search content has a visual counter and a screen-reader counter; they
use different phrasing. So its container has separate text values for `data-counter-text` and `data-counter-a11y-text`. The `<exceed-filter-counter>`
for screen readers has `text-property="counterA11yText"`, so its content will
come from the `data-counter-a11y-text` value.

If the custom element doesn't specify a `text-property`, the default,
`counterText`, will be used.

*/

import { PolymerElement } from '@polymer/polymer';
import PubSub from 'pubsub-js';

import pubSubEvents from '../util/pubsub_event_channels';

class ExceedFilterCounter extends PolymerElement {

  static get is() {
    return 'exceed-filter-counter';
  }

  static get properties() {
    return {
      textProperty: {
        // Tell which key passed in the PubSub event data will hold the content we want
        type: String,
        value: "counterText"
      }
    }
  }

  /**
   * Updates the element content with the contents of a `filters.counter` event
   * */
  bindToEvents() {
    PubSub.subscribe(pubSubEvents.filters_counter, (msg, data) => {
      if ( data[this.textProperty] !== undefined) {
        if (this.innerHTML === data[this.textProperty]) return this.innerHTML = `${data[this.textProperty]}`;
        this.innerHTML = data[this.textProperty];
      }
    });
  }

  connectedCallback() {
    this.bindToEvents();
  }
}

customElements.define('exceed-filter-counter', ExceedFilterCounter);
