/**
 * Component: <exceed-external-video-tracker>
 * Usage: Enrolls an user on a video page component
 * and tracks its progress. Once a the user has reached a certain point, it triggers
 * a request to mark the video activity as completed
 * this is used only for pages
 * */

import { PolymerElement } from '@polymer/polymer';
import axios from '../util/axios';
import PubSub from "pubsub-js";
import pubSubEvents from "../util/pubsub_event_channels";
import Player from '@vimeo/player';

class ExceedExternalVideoTracker extends PolymerElement {

  static get is() {
    return 'exceed-external-video-tracker';
  }

  static get properties() {
    return {
      doTrack: {
        // set to true only if the page component is not completed
        type: Boolean,
        value: false
      },
      enrollmentTargetUrl: {
        // the url to send request to enroll the user
        type: String,
        value: ''
      },
      enrollmentId: {
        // the id  of the page component enrollment
        type: String,
        value: ''
      },
      completionTargetUrl:{
        // url used for completing the video activity
        type: String,
        value: '/student/video_activity_completions'
      },
      videoProgressUrl: {
        // url used for updating enrollment video status
        type: String,
        value: 'enrollment_video_statuses/update/'
      },
      vimeoPlayerSelector: {
        type: String,
        value: 'vimeo'
      },
      youtubePlayerSelector: {
        type: String,
        value: 'youtube'
      },
      facebookPlayerSelector: {
        type: String,
        value: 'facebook'
      }
    }
  }

  triggerUserEnrollmentRequest(){
    if (!this.enrollmentId && this.enrollmentTargetUrl){
      axios.post(this.enrollmentTargetUrl, {})
      .then((response) => {
        this.enrollmentId = response.data.enrollment.id;
      });
    }
  }

  triggerUserCompletionRequest(timeWatched, disableProgressChecking){
    if (this.enrollmentId) {
      axios.post(this.completionTargetUrl, {enrollment_id: this.enrollmentId, time_watched: timeWatched})
      .then((response) => {
        disableProgressChecking();
      });
    }
  }

  triggerVideoProgressUpdate(currentTime, videoFinished) {
    if (this._enrollmentVideoStatusId) {
        axios.post(this.videoProgressUrl, {id: this._enrollmentVideoStatusId, current_time: currentTime, is_video_finished: videoFinished})
            .then((response) => {

    });
    }
  }

  trackVimeoVideo(){
    this._player = new Player(this._externalVideoObject);
    var disableProgressChecking = () => {
      this._player.off('progress');
    };
    disableProgressChecking.bind(this);

    this._player.on('bufferstart', () => {
      this.triggerUserEnrollmentRequest();
      this._player.off('bufferstart')
    });

    this._player.on('progress', (data) => {
      var videoDuration = data.duration,
          currentTime   = data.seconds,
          timeLeft      = videoDuration - currentTime,
          gracePeriodToMarkComplete = 30;

      if (videoDuration <= 60) { gracePeriodToMarkComplete = 6 }
      if (gracePeriodToMarkComplete > 0 && timeLeft < gracePeriodToMarkComplete) { this.triggerUserCompletionRequest(currentTime, disableProgressChecking); }
    });
  }

  trackYoutubeVideoByType(){
    var self = this;
    var broadcastType = self.querySelector('[data-video-provider]').dataset.liveBroadcastType;
    if (broadcastType == 'live' || 'upcoming') {
      self.trackYoutubeLiveVideo(broadcastType);
    } else {
      self.trackYoutubeVideo();
    }
  }


  trackYoutubeVideo(){
    this.initializeYoutubePlayer();
    var self = this;
    PubSub.subscribe(pubSubEvents.youtube_loaded, function() {
      var refreshIntervalId = null;
      var disableProgressChecking = function() { clearInterval(refreshIntervalId); };

      self._player = new YT.Player(self._externalVideoObject.id, {
        events: {
          'onStateChange': function(event){
            if (event.data == YT.PlayerState.BUFFERING && !self.enrollmentId) {
              self.triggerUserEnrollmentRequest();
            }
          },
          'onReady': function() {
            refreshIntervalId = setInterval(function() {
              var videoDuration = self._player.getDuration(),
                  currentTime   = self._player.getCurrentTime(),
                  timeLeft      = videoDuration - currentTime,
                  gracePeriodToMarkComplete = 30;
              if (videoDuration <= 60) { gracePeriodToMarkComplete = 6 }
              if (gracePeriodToMarkComplete > 0 && timeLeft < gracePeriodToMarkComplete) { self.triggerUserCompletionRequest(currentTime, disableProgressChecking); }
            }, 3000);
          }
        }
      });
    });
  }

  trackYoutubeLiveVideo(broadcastType){
    this.initializeYoutubePlayer();
    var self = this;
    PubSub.subscribe(pubSubEvents.youtube_loaded, function() {
        var refreshIntervalId = null;
        var disableProgressChecking = function () {
            clearInterval(refreshIntervalId);
        };

        self._player = new YT.Player(self._externalVideoObject.id, {
            events: {
                'onStateChange': function (event) {
                    var currentTime = self._player.getCurrentTime();
                    var videoFinished = false;
                    if (event.data == YT.PlayerState.BUFFERING && !self.enrollmentId) {
                        self.triggerUserEnrollmentRequest();
                    }
                    if (event.data == YT.PlayerState.PLAYING) {
                        self.triggerVideoProgressUpdate(currentTime, videoFinished);
                        refreshIntervalId = setInterval(function () {
                          self.triggerVideoProgressUpdate(currentTime, videoFinished)
                        }, 15000);
                    }
                    if (event.data == YT.PlayerState.PAUSED) {
                        self.triggerVideoProgressUpdate(currentTime, videoFinished);
                        clearInterval(refreshIntervalId);
                    }
                    if (event.data == YT.PlayerState.ENDED) {
                        var videoFinished = true;
                        self.triggerVideoProgressUpdate(currentTime, videoFinished);
                        self.triggerUserCompletionRequest(currentTime, disableProgressChecking);
                    }
                }
            }
        });
    });
  }

  trackFacebookVideo(){
    var self = this;
    document.addEventListener('init.fbvideoplayer', function(event){
      self._player = event.detail;
      var refreshIntervalId = null;
      var playingEventHandler;
      var disableProgressChecking = function() {
        clearInterval(refreshIntervalId);
      };

      playingEventHandler = self._player.subscribe('startedPlaying', function(e) {
        playingEventHandler.release('startedPlaying');
        self.triggerUserEnrollmentRequest();

        refreshIntervalId = setInterval(function() {
          var videoDuration = self._player.getDuration(),
              currentTime = self._player.getCurrentPosition(),
              timeLeft = videoDuration - currentTime,
              gracePeriodToMarkComplete = 30;
          if (videoDuration <= 60) { gracePeriodToMarkComplete = 6 }
          if (gracePeriodToMarkComplete > 0 && timeLeft < gracePeriodToMarkComplete) { self.triggerUserCompletionRequest(currentTime, disableProgressChecking); }
        }, 3000);
      });
    });
  }

  initializeYoutubePlayer(){
    // load the IFrame Player API code asynchronously
    const youtubeApiUrl = "https://www.youtube.com/iframe_api";

    if (document.querySelectorAll(`script[src="${youtubeApiUrl}"]`).length == 0){
      var tag = document.createElement('script');
      tag.src = youtubeApiUrl;
      var firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

      window.onYouTubeIframeAPIReady = function(){
        PubSub.publish(pubSubEvents.youtube_loaded);
      }
    }
  }

  init() {
    if (!this.doTrack){
      return;
    }

    this._externalVideoObject = this.querySelector('[data-video-provider]');
    this._videoProvider = this._externalVideoObject.dataset.videoProvider
    if (this._videoProvider == "vimeo"){
      this.trackVimeoVideo();
    }
    else if(this._videoProvider == "youtube"){
      this.trackYoutubeVideoByType();
    }
    else if(this._videoProvider == "facebook"){
      this.trackFacebookVideo();
    }

    this._enrollmentVideoStatusId = this.querySelector('[data-video-provider]').dataset.videoControl
  }

  connectedCallback() {
    super.connectedCallback();
    this.init();
  }
}

customElements.define('exceed-external-video-tracker', ExceedExternalVideoTracker);
