/**
 * Component: <exceed-assessment-response-add-file>
 * Usage: USED ONLY FOR ASSESSMENT RESPONSES. Handles the uploading of files to an assessments file upload question.
 * Notes:
 * - Requires specific markup inside the custom element
 *   - A file upload UI (input, choose button and the JS that manages it)
 * - Listens for an event and automatically triggers the system file dialog (by doing a `click()` on the choose button).
 * - IMPORTANT: At this stage, the mechanics of handling the direct file upload are still managed via
 *   `app/assets/javascripts/default/components/uploadfile.js`.
 *
 * EVENTS:
 *
 * Listeners:
 *  - uploadfile start and complete events
 *  - `page.addfiledialog` pubsub event (triggered by pages-add-component when the user clicks to add a file)
 * */

import { PolymerElement } from '@polymer/polymer';
import axios from '../util/axios'
import PubSub from "pubsub-js";
import pubSubEvents from "../util/pubsub_event_channels";
import getElementFromRawHtml from '../util/get_element_from_raw_html';

class ExceedAssessmentResponseAddFile extends PolymerElement {

  static get is() {
    return 'exceed-assessment-response-add-file';
  }

  static get properties() {
    return {
      questionId: {
        // The ID of the question the file is being uploaded to.
        type: String,
        value: ''
      },
      multipleUploaders: {
        // lets us know if we're the only uploader on the page or if there is multiple
        type: String,
        value: "false"
      },
      createEndpoint: {
        // The endpoint we POST to for creating components. The response will contain the rendered component.
        type: String,
        value: ''
      },
      uploadFileControlsSelector: {
        // The selector for the upload UI (which is managed via `app/assets/javascripts/default/components/uploadfile.js`)
        type: String,
        value: '.uploadfile'
      },
      panelClass: {
        //the panel class, removed when a file has been uploaded
        type: String,
        value: 'pgcomponent--panel'
      },
      hiddenClass: {
        // the class that we toggle to hide this component (by default) and show it again when it's uploading
        type: String,
        value: 'a11y-atonly'
      },
      disabledButtonClass: {
        //the class that we remove from the form button once a file has been uploaded
        type: String,
        value: ''
      },
      buttonId: {
        //the form submission button
        type: String,
        value: 'assessment-response-submit'
      },
    }
  }

  /**
   * When we do an update of the file (after the initial creation), we replace the whole element
   * with the server response
   * */
  replaceWithRenderedFile(componentHtml) {
    let parentComponentEl = this.parentElement;
    this.classList.remove(this.panelClass);
    parentComponentEl.querySelector('.pgcomponent').innerHTML = componentHtml;
  }

  // polls the transcode endpoint every 10 seconds, if the video has been transcoded
  // calls the get endpoint and refreshes the html
  monitorTranscodeProgress(mediaAsset = {}) {
    if (typeof(window[`media_asset_interval_${mediaAsset.id}`]) == "undefined") {
      window[`media_asset_interval_${mediaAsset.id}`] = setInterval(function() {
        axios.get(`/media_assets/${mediaAsset.id}/transcode_state`)
          .then((response) => {
            if (response.data.transcode_state == "finished") {
              axios.get(`/media_assets/${mediaAsset.id}`)
                .then((response) => {
                  document.getElementById(`media_asset_${mediaAsset.id}`).innerHTML = response.data;
                  clearInterval(window[`media_asset_interval_${mediaAsset.id}`]);
                });
            }
          });
      }, 10000);
    }
  }

  // sends the media id to the assessment question update endpoint
  updateAssessmentQuestion(mediaAsset) {
    let action = document.querySelector(`[data-spa-question-id="${this.questionId}"]`).action;
    let key = `media_asset_id_${this.questionId}`;
    let formData = {};
    formData[key] = mediaAsset.id;
    axios.post(action, formData);
  }

  /**
   * Creates a new MediaAsset file and either updates the form on the page or creates an assessment question response
   * depending on multipleUploaders value
   * */
  sendFileContent(uploadedFilePath, uploadedFileName, contentType) {
    if (this.createEndpoint == "") {
      return false;
    }

    let formData = {
      media_file_upload: {
        file: uploadedFilePath,
        name: uploadedFileName,
        content_type: contentType,
        generate_action_dispatch: true
      },
    };

    //creates the media asset
    axios.post(this.createEndpoint, formData)
      .then((response) => {
        let mediaAsset = JSON.parse(response.data.media_asset);

        if (this.multipleUploaders == "true") {
          //if multiple uploaders on the page, update the assessment question
          //in the background
          this.updateAssessmentQuestion(mediaAsset)
        } else {
          //otherwise user must submit the form. ensure input is updated
          document.getElementById(`media_asset_id`).value = mediaAsset.id;
          document.getElementById(this.buttonId).classList.remove(this.disabledButtonClass);
        }

        //replace the uploader with the media asset that was uploaded
        this.replaceWithRenderedFile(response.data.html);

        //if the media asset was a video, monitor the transcode progress
        if (mediaAsset.media_content_type.includes("video/") && mediaAsset.transcode_state !== "finished") {
          this.monitorTranscodeProgress(mediaAsset);
        }
      })
  }

  /**
   * bound to the jQuery complete event from the `.uploadfile.js` javascript and calls the `sendFileContent`
   * to create and save the file as a MediaAsset
   * * */
  handleUploadCompleteEvent (event) {
    this.sendFileContent(event.detail.path, event.detail.fileName, event.detail.contentType);
  }

  /**
   * Set up the internals of the direct upload - we need to make sure that the file input has a unique id and that
   * the label is pointing to it.
   * */
  setUploadControlInternals() {
    let fileInputId = `file_input_${this.id}`;
    this._fileUploaderEl.querySelector('.uploadfile__choosebtn').setAttribute('for', fileInputId);
    this._fileUploaderEl.querySelector('.uploadfile__input').id = fileInputId;
  }

  /**
   * If the file uploader has been loaded via XHR we need to make sure that the (older) file upload js is initialised
   * */
  initUploadControls() {
    if (this._fileUploaderEl && window.jQuery && (!this._fileUploaderEl.dataset.intelluminitialized)) {
      new window.Intellum.uploadFile($(this._fileUploaderEl));
    }
  }

  /**
   * Sets up the elements and binds them
   * */
  init() {
    this._fileUploaderEl = this.querySelector(this.uploadFileControlsSelector);

    if (this._fileUploaderEl == null) {
      return false;
    }

    this._fileUploaderEl.addEventListener('uploadfile.complete', this._boundHandleUploadCompleteEvent);
    this._fileUploaderEl.addEventListener('uploadfile.start', this._boundHandleUploadStartEvent);
    this._fileUploaderEl.addEventListener('uploadfile.cancel', this._boundHandleUploadCancelEvent);
  }

  connectedCallback() {
    super.connectedCallback();
    this.init();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this._fileUploaderEl.removeEventListener('uploadfile.complete', this._boundHandleUploadCompleteEvent);
    this._fileUploaderEl.removeEventListener('uploadfile.start', this._boundHandleUploadStartEvent);
    this._fileUploaderEl.removeEventListener('uploadfile.cancel', this._boundHandleUploadCancelEvent);
    PubSub.unsubscribe(this._triggerEventsSubscription);
  }

  constructor() {
    super();
    this._boundHandleUploadCompleteEvent = this.handleUploadCompleteEvent.bind(this);
  }

}

customElements.define('exceed-assessment-response-add-file', ExceedAssessmentResponseAddFile);
