/*
Component: <exceed-remote-checkbox>
Usage:  This component handles a simple checkbox that could be inside of a form
        or use ajax on change by setting data-remote="true" attribute on the checkbox.
Notes:  Use the exceed_check_box_tag form helper to easily create this element.
*/

import { PolymerElement } from '@polymer/polymer';
import queryString from 'query-string';

class ExceedRemoteCheckbox extends PolymerElement {

  static get is() {
    return 'exceed-remote-checkbox';
  }

  static get properties() {
    return {
      inputSelector: {
        type: String,
        value: 'input[type="checkbox"]'
      },
      successMessage: {
        type: String,
        value: ''
      },
      errorMessage: {
        type: String,
        value: ''
      }
    }
  }

  // Reacts to remote form responses handled by Rails UJS data-remote
  // attribute.
  bindRemoteResponses() {
    var self = this;
    // NOTE: this uses jQuery because Rails < 5.1 still use it for UJS, remove this after
    //       upgrade to rails 5.1
    if (window.jQuery && window.Intellum && Intellum.flashnotice) {
      if (this.successMessage.length > 0) {
        jQuery(this.inputEl).on('ajax:success', function(e, data, status, xhr){
          Intellum.flashnotice.show(self.successMessage);
        })
      }
      if (this.errorMessage.length > 0) {
        jQuery(this.inputEl).on('ajax:error',function(e, xhr, status, error){
          Intellum.flashnotice.show(self.errorMessage, 'warning');
        });
      }
    }
  }

  // This helps the remote checkboxtag to send value within the ajax request on
  // uncheck action.
  bindInputChange() {
    this.inputEl.addEventListener('change', (event) => {
      // NOTE: this uses jQuery because Rails < 5.1 still use it for UJS, remove this after
      //       upgrade to rails 5.1
      if (jQuery) {
        jQuery(this.inputEl).data(
          'params',
          jQuery(this.inputEl).prop('name') + '=' + this.inputEl.checked
        );
      }
    });
  }

  initElements() {
    if (this.inputSelector) {
      this.inputEl = this.querySelector(this.inputSelector);
    }
  }

  connectedCallback() {
    super.connectedCallback();
    this.initElements();

    if (this.inputEl) {
      this.bindRemoteResponses();
      this.bindInputChange();
    }
  }
}

customElements.define('exceed-remote-checkbox', ExceedRemoteCheckbox);
