/*
Component: <exceed-continue-modal>

Operates a special modal that guides the user to the next activity,
particularly after a video ends.

This can be opened in external js by having the js add the openClass to the exceed-continue-modal element.
*/

import ExceedModal from './exceed-modal';
import PubSub from 'pubsub-js';
import pubSubEvents from '../util/pubsub_event_channels';
import {trapFocus, releaseFocus} from '../util/a11y';

class ExceedContinueModal extends ExceedModal {

  static get properties() {
    return {
      // Inherited properties with new defaults
      openClass: {
        // add this CSS class to open the modal
        type: String,
        value: 'continuemodal--open'
      },
      visibleClass: {
        // add this CSS class as a second step after opening to make the dialog visible (for transitions)
        type: String,
        value: 'continuemodal--visible'
      },
      // New properties for this
      countdownElSelector: {
        type: String,
        value: '.continuecard__timer'
      },
      nextItemUrl: {
        type: String,
        value: ''
      },
      triggerElSelector: {
        // We need a default trigger el for auto-opened modal, so that closing modal creates appropriate focus
        type: String,
        value: '[data-action="replay"]'

      }
    }
  }

  static get is() {
    return 'exceed-continue-modal';
  }

  /**
   * Inherited methods used unchanged:
   * - isEventForThisDialog
   * - handleAria
   * - openModal
   * - listenToEventBus
   * - escKeydownHandler
   * */

  /**
   * Opens the modal (including allowing for a transition)
   * Replaces inherited method
   * */
  doModalOpen() {
    // Save trigger so we can re-focus before opening modal
    this._modalTriggerEl ?? document.activeElement;
    this.classList.add(this.openClass);
    void this.offsetWidth; // force redraw
    this.classList.add(this.visibleClass);
    setTimeout(() => {
      // Do truncate on title; it couldn't happen while content was hidden
      if (window.Intellum && window.Intellum.truncate !== undefined) {
          window.Intellum.truncate.init({scope: this});
      }
    }, this.transitionTime);

    this.isOpen = true;

    // Refocus on trigger so it will get remembered by trapFocus
    if (this._modalTriggerEl) {
      this._modalTriggerEl.focus();
    }

    // Trap focus, saving bound tab key handler returned from function so that #doModalClose can unbind it
    this._boundTabKeydownHandler = trapFocus(this).boundTabKeydownHandler;

    // Handle Esc key separately from trapping focus for tabs
    document.addEventListener('keydown', this._boundEscKeydownHandler);

    // Start the timer if there is one
    this.runTimer();
  }

  /**
   * Closes the modal (including allowing for a transition)
   * Replaces inherited method
   * */
  doModalClose() {
    if (this.isOpen) {
      this.resetTimer();

      this.classList.remove(this.visibleClass);
      setTimeout(() => {
        this.classList.remove(this.openClass);
        // Only reset the margin offset and body freeze classes if we know we're closing the first modal opened
      }, this.transitionTime);
      this.isOpen = false;

      releaseFocus(this._modalTriggerEl, this._boundTabKeydownHandler);
      document.removeEventListener('keydown', this._boundEscKeydownHandler);
    }
  }

  /**
   * Respond to events
   * Replaces inherited method
   * */
  closeModal() {
    this.doModalClose();
  }

  /**
   * Listen for event bus events and handle any events that are directed towards this instance
   * Replaces inherited method
   * */
  listenToEventBus() {
    this._modalEventSubscriber = PubSub.subscribe(pubSubEvents.modal, (msg, eventData) => {
      if (this.isEventForThisDialog(eventData)) {
        if (msg === pubSubEvents.modal_open) {
          this.openModal();
        } else if (msg === pubSubEvents.modal_close) {
          this.closeModal();
        }
      }
    });
  }
  /**
   * Unused inherited methods to overwrite - called in inherited connectCallback
   */
  initFormListener() {}
  handleHistoryEvents() {}

  /**
   * Set up the countdown to go to the next thing
   * Content of countdownEl must be number of seconds, i.e. 10
   * */
  setTimer() {
    this._countdownEl = this.querySelector(this.countdownElSelector);
    if (this._countdownEl) {
      let timeInSecs = this._countdownEl.innerText;
      if (!isNaN(timeInSecs)) {
        this._countdownNumber = Number(timeInSecs);
      }
    }
  }

  /**
   * Activate the countdown to go to the next thing
   * */
  runTimer() {
    if (this._countdownEl && this._countdownNumber) {
      let newTime = this._countdownNumber;
      this._timer = setInterval(() => {
        newTime--;
        this._countdownEl.innerText = newTime;
        if (newTime == 0) {
          clearInterval(this._timer);
          window.location.href = this.nextItemUrl;
        }
      }, 1000);
    }
  }

  /**
   * Stop and reset the timer when modal is closed
   * */
  resetTimer() {
    if (this._timer) {
      clearInterval(this._timer);
      this._countdownEl.innerText = this._countdownNumber;
      this._timer = null;
    }
  }

  /**
   * Handle auto-launched modal, triggered by class change on container
   * */
  openModalOnClassChange(mutationsList) {
    // the only recorded mutations are changes to class - check to see if the open class was added
    if (mutationsList[0].target.classList.contains(this.openClass) && mutationsList[0].oldValue.indexOf(this.openClass) == -1) {
      this._modalTriggerEl = document.querySelector(this.triggerElSelector);
      this.doModalOpen();
    }
  }

  connectedCallback() {
    super.connectedCallback();
    this._boundOpenModalHandler = this.openModalOnClassChange.bind(this);
    // Bind observers
    this._openModalObserver = new MutationObserver(this._boundOpenModalHandler);
    this._openModalObserver.observe(this, {attributeFilter: ['class'], attributeOldValue: true});

    this.setTimer();

  }

  disconnectedCallback() {
    super.disconnectedCallback();
    PubSub.unsubscribe(this._modalEventSubscriber);
    this._openModalObserver.disconnnect();
  }

  constructor() {
    super();
  }
}

customElements.define('exceed-continue-modal', ExceedContinueModal);
