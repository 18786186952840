/*
Component: <exceed-level-xp-subscribe>
Usage: This component shows as its contents the current Level XP of a user
       if available.
       Initial contents should be set manually when the template is rendered.
       On startup it will subscribe to the given channel to listen for
       Level XP events.
       There are 2 possible events:
       - "set": It means current user's Level XP is known by Exceed. It includes
           a "value" field with the actual XP value.
           The component will update its contents with the given value.     
       - "outdated": It means Exceed doesn't know the current XP, or it is outdated.
           The component will update its contents with the value of the `onOutdated`
           property.
*/

import centrifugo from '../util/centrifugo';
import { PolymerElement } from '@polymer/polymer';

export default class ExceedLevelXpSubscribe extends PolymerElement {

  static get is() {
    return 'exceed-level-xp-subscribe';
  }

  static get properties() {
    return {
      // Centrifugo channel name to subscribe to.
      channel: {
        type: String
      },
      // Content to show when current user XP is outdated.
      onOutdated: {
        type: String,
        value: '<span class="loading--textdots"><span class="a11y-atonly">Loading</span></span>'
      }
    }
  }

  handleMessage(message) {
    if (message.data.event == "set") {
      this.innerHTML = message.data.value;
    } else if (message.data.event == "outdated") {
      this.innerHTML = this.onOutdated;
    }
  }

  init() {
    if (this.centrifugo && !this._subscription) {
      this._subscription = this.centrifugo.subscribe(this.channel, this.handleMessage.bind(this));
    }
  }

  connectedCallback() {
    super.connectedCallback();
    this.centrifugo = centrifugo;
    this.init();
  }

}

customElements.define('exceed-level-xp-subscribe', ExceedLevelXpSubscribe);
