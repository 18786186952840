/*
Component: <exceed-scroll-row>

Wrap this around content that includes a scrollable row of card items
to add functionality to scroll buttons.
*/


// Here's a figure that serves as a reference guide for the scroll
// calculations. (Calculations differ if document is rtl.)
//
//                    this._scrollEl.clientWidth
//                   ■──────────────────────────■
//
//          ■────────■ this._scrollEl.scrollLeft

//                    this._scrollEl.scrollLeft ■────────■
//                      if rtl (negative number)
//
//                   ┌──────────────────────────┐
//          ┌────────┼─────────̞─────────̞────────┼────────┐
//          │        │        │        │        │        │
//          │        │        │        │        │        │
//          └────────┼─────────̝─────────̝────────┼────────┘
//                   └──────────────────────────┘
//
//          ■───────────────────-──────■ this._scrollEl.children[3].offsetLeft
//                   ■─────────────────■ this._scrollEl.children[1].offsetLeft if rtl
//
//                            ■────────■ this._scrollEl.children[2].clientWidth
//
//

import { PolymerElement } from '@polymer/polymer';
import debounce from '../util/debounce';

class ExceedScrollRow extends PolymerElement {

  static get is() {
    return 'exceed-scroll-row';
  }

  static get properties() {
    return {
      scrollElSelector: {
        // selector for the scrolling row
        type: String,
        value: '.catalogcards__list'
      },
      scrollItemElSelector: {
        // selector for each item in the scrolling row
        type: String,
        value: '.catalogcards__listitem'
      },
      headerElSelector: {
        // selector for row header (outside this element)
        // must be parent of this element, or both this and headerEl must share a parent
        type: String,
        value: '.catalogcards__header'
      },
      prevButtonElSelector: {
        // selector for the next button
        type: String,
        value: '.catalogcards__navbutton--prev'
      },
      nextButtonElSelector: {
        // selector for the next button
        type: String,
        value: '.catalogcards__navbutton--next'
      },
      buttonHiddenClass: {
        // class to add to button if it is unneeded
        // (can't use hidden attr because display style is set for buttons)
        type: String,
        value: 'catalogcards__navbutton--hidden'
      },
      listScrollingClass: {
        // class to add to list to enhance iOS scrolling
        type: String,
        value: 'catalogcards__list--scrolling'
      },
        // height of each button when displayed for desktop
      buttonHeight: {
        type: Number,
        value: 48
      }
    }
  }

  hideUnneededNavigation() {
    let isHidden,
        lastChild = this._scrollEl.children[this._scrollEl.children.length - 1];
    if (this._isRtl) {
      isHidden = lastChild.offsetLeft >= 0;
    } else {
      isHidden = this._scrollEl.clientWidth >= lastChild.offsetLeft + lastChild.clientWidth;
    };
    if (isHidden) {
      this._prevButtonEl.classList.add(this.buttonHiddenClass);
      this._nextButtonEl.classList.add(this.buttonHiddenClass);
    } else {
      this._prevButtonEl.classList.remove(this.buttonHiddenClass);
      this._nextButtonEl.classList.remove(this.buttonHiddenClass);
    }
  }

  updateControls() {
    if (this._prevButtonEl && this._nextButtonEl) {
      this.hideUnneededNavigation();
      let isPrevEnabled = (this._isRtl) ? this._scrollEl.scrollLeft < 0 : this._scrollEl.scrollLeft > 0;
      let isNextEnabled = (this._isRtl) ? this._scrollEl.clientWidth - this._scrollEl.scrollLeft < this._scrollEl.scrollWidth : this._scrollEl.clientWidth + this._scrollEl.scrollLeft < this._scrollEl.scrollWidth;

      if (isPrevEnabled) {
          this._prevButtonEl.removeAttribute('disabled');
      } else {
          this._prevButtonEl.setAttribute('disabled', 'disabled');
      }

      if (isNextEnabled) {
          this._nextButtonEl.removeAttribute('disabled');
      } else {
          this._nextButtonEl.setAttribute('disabled', 'disabled');
      }
    }
  }

  placeControls() {
    // css shifts the buttons between the sides and the header as needed,
    // but if they're on the sides the vertical positioning needs adjustment
    // to center the buttons vertically with the row items
    if (this._prevButtonEl && this._nextButtonEl && this._scrollItemsEl.length) {
      if (window.getComputedStyle(this._prevButtonEl).getPropertyValue('position') == 'absolute') {
        let rowTop = this.offsetTop; //accounts for header margin
        let rowItemHeight = this._scrollItemsEl[0].offsetHeight;
        let buttonPosition = rowTop + (rowItemHeight / 2) - (this.buttonHeight / 2);
        this._prevButtonEl.style.top = `${buttonPosition}px`;
        this._nextButtonEl.style.top = `${buttonPosition}px`;
      }
    }
  }

  // Add momentum to scroll on iOS
  addMomentumToScroll() {
    if (this._scrollEl.scrollWidth > this._scrollEl.clientWidth) {
      this._scrollEl.classList.add(listScrollingClass);
    } else {
      this._scrollEl.classList.remove(listScrollingClass);
    }
  }

  handleWindowResize() {
    this.resetComponent();
    this.limitScroll();
  }

  throttle(fn, defer) {
    // Initially we don't wait for the deferral period
    let wait = false;

    return () => {
      if (!wait) {

        // If we're not waiting, go ahead and call
        // the throttled function.
        fn.call();

        // And start a new waiting period.
        wait = true;

        // Keep waiting for as long as the deferral
        // period specifies.
        window.setTimeout(function () {
            wait = false;
        }, defer);
      }
    };
  }

  limitScroll() {
    if (this._scrollEl.children.length) {
      let last = this._scrollEl.children[this._scrollEl.children.length - 1],
          max = last.offsetLeft + last.clientWidth - this._scrollEl.children[0].offsetLeft - this._scrollEl.children.clientWidth;
      // Don't use `Math.max()` here to prevent unneccessary jank.
      if (this._scrollEl.scrollLeft > max) {
        this._scrollEl.scrollLeft = max;
      }
    }
  }

  smoothScrollTo(offset, callback) {
    let start = this._scrollEl.scrollLeft,
        distance = offset,
        duration = 400, // constant for now, but could be made variable
        timeStart,
        timeElapsed;

    let easeInOutQuad = (dt, x0, xF, T) => {
      var dx;
      dt /= T / 2;
      if (dt < 1) {  // ease-in
        dx = (xF - x0) / 2 * dt * dt;
      } else { // ease-out
        dt--;
        dx = (x0 - xF) / 2 * (dt * (dt - 2) - 1);
      }
      return x0 + dx;
    };

    let end = () => {
      this._scrollEl.scrollLeft = offset;
      if (typeof callback == "function") {
        callback();
      }
    };

    let loop = (time) => {
      timeElapsed = time - timeStart;
      this._scrollEl.scrollLeft = easeInOutQuad(timeElapsed, start, distance, duration);
      if (timeElapsed < duration) {
        window.requestAnimationFrame(loop);
      } else {
        end();
      }
    };

    window.requestAnimationFrame((time) => { timeStart = time; loop(time); });
  }

  handlePrevClick(event) {
    if (this._scrollEl.childNodes.length) {
      let items = this._scrollEl.children;
      let scrollLeft, itemLeft, itemRight;

      // Find closest previous item that isn't already completely visible
      for (var i = items.length - 1; i >= 0; i--) {
        itemLeft = items[i].offsetLeft - this._scrollEl.scrollLeft;
        itemRight = itemLeft + items[i].clientWidth;
        if (this._isRtl) {
          if (itemRight > this._scrollEl.clientWidth) {
            scrollLeft = this._scrollEl.scrollLeft + itemLeft;
            break;
          }
        } else {
          if (itemLeft < 0) {
            scrollLeft = itemLeft + items[i].clientWidth + this._scrollEl.scrollLeft - this._scrollEl.clientWidth;
            break;
          }
        }
      }
      this._isScrolling = true;
      this.smoothScrollTo(scrollLeft, () => {
        this._isScrolling = false;
        this.updateControls();
      })
    }
  }

  handleManualScrolling(event) {
    this.updateControls();
  }

  handleNextClick(event) {
    if (this._scrollEl.childNodes.length) {
      let items = this._scrollEl.children;
      let scrollLeft, itemLeft, itemRight;

      // Find next item that isn't already completely visible
      for (var i = 0; i < items.length; i++) {
        itemLeft = items[i].offsetLeft - this._scrollEl.scrollLeft;
        itemRight = itemLeft + items[i].clientWidth;
        if (this._isRtl) {
          if (itemLeft < 0) {
            scrollLeft = this._scrollEl.scrollLeft - (this._scrollEl.clientWidth + itemRight);
            break;
          }
        } else {
          if (itemRight > this._scrollEl.clientWidth) {
            scrollLeft = itemLeft + this._scrollEl.scrollLeft;
            break;
          }
        }
      }
      this._isScrolling = true;
      this.smoothScrollTo(scrollLeft, () => {
        this._isScrolling = false;
        this.updateControls();
      })
    }
  }

  bindEventHandlers() {
    if (this._prevButtonEl) {
      this._boundPrevButtonEventHandler = this.handlePrevClick.bind(this);
      this._prevButtonEl.addEventListener('click', this._boundPrevButtonEventHandler);
    }
    if (this._nextButtonEl) {
      this._boundNextButtonEventHandler = this.handleNextClick.bind(this);
      this._nextButtonEl.addEventListener('click', this._boundNextButtonEventHandler);
    }
    if (this._prevButtonEl && this._nextButtonEl) {
      this._boundManualScrollingHandler = debounce(() => {
        this.handleManualScrolling();
      }, 250).bind(this);
      this._scrollEl.addEventListener('scroll', this._boundManualScrollingHandler);
    }
  }

  unbindEventHandlers() {
    if (this._prevButtonEl) {
      this._prevButtonEl.removeEventListener('click', this._boundPrevButtonEventHandler);
    }
    if (this._nextButtonEl) {
      this._nextButtonEl.removeEventListener('click', this._boundNextButtonEventHandler);
    }
    if (this._prevButtonEl && this._nextButtonEl) {
      this._scrollEl.removeEventListener('scroll', this._boundManualScrollingHandler);
    }
  }

  resetComponent() {
    this.updateControls();
    this.placeControls();
  }

  initComponent() {
    this._isRtl = document.dir === 'rtl';
    this._scrollEl = this.querySelector(this.scrollElSelector);
    this._headerEl = this.closest(this.headerElSelector) || this.parentElement.querySelector(this.headerElSelector);
    if (this._scrollEl && this._headerEl) {
      this._prevButtonEl = this._headerEl.querySelector(this.prevButtonElSelector);
      this._nextButtonEl = this._headerEl.querySelector(this.nextButtonElSelector);
      this._scrollItemsEl = this.querySelectorAll(this.scrollItemElSelector);
      this.bindEventHandlers();
      this.updateControls();
      this.placeControls();
      this._isScrolling = false;
    }
  }

  connectedCallback() {
    super.connectedCallback();
    this.initComponent();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    if (this._scrollEl) {
      this.unbindEventHandlers();
    }
  }
}

customElements.define('exceed-scroll-row', ExceedScrollRow);
