/**
 * Component: <exceed-assessment-response-status-change-subscribe>
 * Usage: This component reloads the page of the grader when assessments are graded via PubSub.
 *        https://github.com/intellum/exceed/issues/38603
 **/

import centrifugo from '../util/centrifugo';
import { PolymerElement } from '@polymer/polymer';

export default class ExceedAssessmentResponseStatusChangeSubscribe extends PolymerElement {

  static get is() {
    return 'exceed-assessment-response-status-change-subscribe';
  }

  static get properties() {
    return {
      // Centrifugo channel name to subscribe to
      channel: {
        type: String
      }
    }
  }

  handleMessage(message) {
    window.location.reload();
  }

  init() {
    if (this.centrifugo && !this._subscription) {
      this._subscription = this.centrifugo.subscribe(this.channel, this.handleMessage.bind(this));
    }
  }

  connectedCallback() {
    super.connectedCallback();
    this.centrifugo = centrifugo;
    this.init();
  }

}

customElements.define('exceed-assessment-response-status-change-subscribe', ExceedAssessmentResponseStatusChangeSubscribe);
