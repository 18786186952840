/*
Component: <exceed-countdown-text>

This outputs text telling how far away a target time is, in minutes, hours, or days.

It prefers to base this countdown on the user's chosen time zone (in the app)
rather than the computer's local time.

It needs to know the user's tz offset for both now and the target time in case
there's a time change between the two, which might, sometimes, affect
the date that each time lands on for the user.
*/

import { PolymerElement } from '@polymer/polymer';

let textInOneMinute = "in 1 minute";
let textInMinutes = "in {{COUNT}} minutes";
let textInOneHour = "in 1 hour";
let textInHours = "in {{COUNT}} hours";
let textTomorrow = "tomorrow";
let textInDays = "in {{COUNT}} days";
let textNow = "now";

if (window.Intellum && window.Intellum.i18nStrings) {
  textInOneMinute = window.Intellum.i18nStrings.in_one_minute;
  textInMinutes = window.Intellum.i18nStrings.in_minutes;
  textInOneHour = window.Intellum.i18nStrings.in_one_hour;
  textInHours = window.Intellum.i18nStrings.in_hours;
  textTomorrow = window.Intellum.i18nStrings.tomorrow;
  textInDays = window.Intellum.i18nStrings.in_days;
  textNow = window.Intellum.i18nStrings.live_now;
}

class ExceedCountdownText extends PolymerElement {

  static get is() {
    return 'exceed-countdown-text';
  }

  static get properties() {
    return {
      targetDateTimeForUser: {
        // date and time of the thing we're counting down to in the user's time zone, with tz offset - required
        // Example: "2020-05-08T22:30:00-0700"
        type: String,
        value: ""
      },
      userTimezoneOffsetForNow: {
        // Timezone offset for user's timezone now in minutes
        type: Number,
        value: new Date().getTimezoneOffset()
      },
      userTimezoneOffsetForTarget: {
        // Timezone offset for user's timezone at target event time in minutes
        type: Number,
        value: new Date().getTimezoneOffset()
      },
      timerInterval: {
        // in seconds
        type: Number,
        value: 60
      },
      textInOneMinute: {
        type: String,
        value: textInOneMinute
      },
      textInMinutes: {
        type: String,
        value: textInMinutes
      },
      textInOneHour: {
        type: String,
        value: textInOneHour
      },
      textInHours: {
        type: String,
        value: textInHours
      },
      textTomorrow: {
        type: String,
        value: textTomorrow
      },
      textInDays: {
        type: String,
        value: textInDays
      },
      textNow: {
        type: String,
        value: textNow
      }
    }
  }

  // Get date in user's time zone, converted to milliseconds
  //   User offset in minutes, positive if less than utc
  //   omit dateTimeString to use current time
  getTimeOfDate(userOffset, dateTimeString) {
    // Method uses these locale and dateStyle choices to return consistent, unambiguous date strings
    const formatLocale = 'en-US';
    const formatDateStyle = 'short';
    let dateString = "";
    // If the user's timezone is not the local timezone, this adjusts the local date to user's
    let d = (dateTimeString == undefined) ? new Date() : new Date(dateTimeString);
    if (userOffset == d.getTimezoneOffset()) {
      // User offset and local offset are the same, no intricate calculations needed
      dateString = new Intl.DateTimeFormat(formatLocale, {dateStyle: formatDateStyle}).format(d);
    } else {
      // HT https://www.techrepublic.com/article/convert-the-local-time-to-another-time-zone-with-this-javascript/

      // Convert to milliseconds
      let localTime = d.getTime();
      // Adjust local time to utc
      let localOffset = d.getTimezoneOffset() * 60000;
      let utc = localTime + localOffset;
      // Adjust utc to user time zone
      let userOffsetInMlsc = -(userOffset * 60000);
      let userTime = utc + userOffsetInMlsc;
      // Turn the converted milliseconds (now in user's time) to date
      dateString = new Intl.DateTimeFormat(formatLocale, {dateStyle: formatDateStyle}).format(new Date(userTime));
    }
    return new Date(dateString).getTime();
  }

  calculateDaysAway() {
    // Get the time in mlsc for the date now in the user's time zone
    let nowTime = this.getTimeOfDate(this.userTimezoneOffsetForNow);
    //  Get the time in mlsc for the date of the target datetime in the user's time zone
    let targetTime = this.getTimeOfDate(this.userTimezoneOffsetForTarget, this.targetDateTimeForUser);

    let diff = targetTime - nowTime;
    let dayDiff = -1;
    if (diff >= 0) {
      dayDiff = Math.floor(diff / (24 * 60 * 60000));
    }
    return dayDiff;
  }

  getTimeRemainingText() {
    let timeRemainingInSeconds = (this._date - Date.now()) / 1000;
    let time_string_key = "";
    let time_string_param_value = "";
    let daysAway = this.calculateDaysAway();

    // If target is today, show how many minutes or hours away (or if it's now)
    if (daysAway == 0) {
      if (timeRemainingInSeconds <= 0) {
        return this.textNow;
      } else if (timeRemainingInSeconds < 120) {
        return this.textInOneMinute;
      } else if (timeRemainingInSeconds < 3600) {
        return this.textInMinutes.replace('{{COUNT}}', Math.floor(timeRemainingInSeconds / 60));
      } else if (timeRemainingInSeconds < 7200) {
        return this.textInOneHour;
      } else {
        return this.textInHours.replace('{{COUNT}}', Math.floor(timeRemainingInSeconds / 3600));
      }
    // If target is tomorrow, say so
    } else if (daysAway == 1) {
      return this.textTomorrow;
    // If target is beyond tomorrow, say how many days away
    } else if (daysAway > 1) {
      return this.textInDays.replace('{{COUNT}}', daysAway);
    // If target has passed, show nothing
    } else {
      return "";
    }
  }

  updateContent() {
    this.innerHTML = this.getTimeRemainingText();
  }

  initElement() {
    if (this.targetDateTimeForUser.length) {
      // Convert passed date/time to js date in milliseconds
      this._date = new Date(this.targetDateTimeForUser).getTime();
      // Set initial text
      this.updateContent();
      // Set timer
      this._timer = window.setInterval(() => {this.updateContent()}, this.timerInterval * 1000);
      }
  }

  connectedCallback() {
    super.connectedCallback();
    this.initElement();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.clearInterval(this._timer);
  }
}

customElements.define('exceed-countdown-text', ExceedCountdownText);
