/*
Component: <exceed-lazyload-section>

Wrap this around content that will be lazyloaded.
*/

import { PolymerElement } from '@polymer/polymer';
import axios from 'axios';

export default class ExceedLazyloadSection extends PolymerElement {

  static get is() {
    return 'exceed-lazyload-section';
  }

  static get properties() {
    return {
      xhrUrl: {
        // the base endpoint for XHR updates
        type: String,
        value: ''
      },
      xhrErrorText: {
        // Error text to use if the load fails
        type: String,
        value: 'The section could not be loaded.'
      }
    }
  }

  lazyLoadRows() {
    let loadRow = () => {
      axios.get(this.xhrUrl)
        .then((response) => {
          this.innerHTML = response.data;
          Intellum.lazyload.init();
          Intellum.util.reinitialize.trigger();
          window.setTimeout(() => {
            this.dataset.contentLoaded = 'true';
          }, 100)
        })
        .catch((error) => {
          this.innerHTML = `<p>${this.xhrErrorText}</p>`;
        });
    };

    let loadVisibleRows = (entries) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          return;
        }
        // Do not reveal the ones already revealed
        if (!entry.target.dataset.lazyLoadContent == 'true') {
          return;
        }
        if (entry.target.dataset.lazyLoadContentLoaded == 'true') {
          return;
        }
        loadRow(entry.target);
        // This is to reduce the performance impact in the main thread, timeout ensures it gets exececuted
        // in 100ms or less.
        window.requestIdleCallback(function () {
          // Do truncate on row contents; it couldn't happen while content was hidden
          if (window.Intellum.truncate !== undefined) {
              window.Intellum.truncate.init({scope: entry.target});
          }
        }, { timeout: 100 });
      });
    };

    let rowObserver = new IntersectionObserver(loadVisibleRows, {
      rootMargin: '800px',
      threshold: 0.5
    });

    rowObserver.POLL_INTERVAL = 100; // poll interval is used by the polyfill

    // start observing
    rowObserver.observe(this);
  }

  initComponent() {
    this.lazyLoadRows();
  }

  connectedCallback() {
    super.connectedCallback();
    this.initComponent();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }
}

customElements.define('exceed-lazyload-section', ExceedLazyloadSection);
