/*
Component: <exceed-pages-outline>
Usage: Dynamically display a pages outline based on h2 and h3 tags throughout the activity

*/

import { PolymerElement } from '@polymer/polymer';

export default class ExceedPagesOutline extends PolymerElement {

  static get is() {
    return 'exceed-pages-outline';
  }

  static get properties() {
    return {
      wrapperElSelector: {
        // wrapper for the menu
        type: String,
        value: '.pageoutline'
      },
      outputElSelector: {
        // selector for the menu
        type: String,
        value: '.pageoutline__output'
      },
      targetElSelector: {
        // selector for the content
        type: String,
        value: '.page__courses'
      },
      headingOffset: {
        // extra spacing when determining the active heading
        type: Number,
        value: 20
      },
      currentLinkClass: {
        type: String,
        value: 'pageoutline__currentlink'
      }
    }
  }

  createListElement(element, attributes, text) {
    const newElement = document.createElement(element);
    if (Object.keys(attributes).length) {
      Object.keys(attributes).forEach((attribute) => {
        newElement.setAttribute(attribute, attributes[attribute]);
      });
    }
    if (text) {
      newElement.textContent = text;
    }
    return newElement;
  }

  checkForScrolling() {
    /* Every 100ms (matches delay set by throttle in scroll.js util),
       we check to see if the isScrolling flag is on.
       If yes, turn it back off; next scroll event will turn it back on.
       If no, scrolling has ended and we can turn the isTargetingAnchor flag
       off so that scrolling will again change the link highlight.
     */
    const timer = window.setInterval(() => {
      if (this._isScrolling) {
        this._isScrolling = false;
      } else {
        // Wait another short interval before surrending control
        // of highlighting to scrolling, to make sure scrolling has
        // really stopped, esp. on FF
        window.setTimeout(() => {
          this._isTargetingAnchor = false;
          window.clearInterval(timer);
        }, 200);
      }
    }, 100);
  }

  highlightSelectedLink() {
    const linkEl = this._outputEl.querySelector(`[data-anchor="${this._urlAnchor}"]`);
    if (linkEl) {
      if (!linkEl.classList.contains(this.currentLinkClass)) {
        this._outputEl.querySelectorAll('[data-anchor]').forEach((el) => {
          el.classList.remove(this.currentLinkClass);
        });
        linkEl.classList.add(this.currentLinkClass);
      }
    }
  }

  handleClickOnList(event) {
    if (this._outputEl.contains(event.target)) {
      event.preventDefault();
      // Update the hash
      history.pushState({}, '', event.target.hash);
      this._urlAnchor = event.target.hash.slice(1);

      // Find header element within target selector and scroll to the location on click
      const el = document.getElementById(event.target.dataset.anchor);
      const elPos =
        el.getBoundingClientRect().top +
        (window.pageYOffset - this._scrollTopOffset);
      this._elPos = elPos;

      // Set flag to prevent scrolling from changing link highlighting
      this._isTargetingAnchor = true;
      // Ensure that isScrolling flag is off; scroll event will reset it to true via #handleCurrentListItem
      this._isScrolling = false;

      if (window.MSInputMethodContext && document.documentMode) {
        // Since window.scrollTo doesn't work for IE11, jump to the position
        // instead of sliding to each menu item clicked
        document.documentElement.scrollTop = elPos;
      } else {
        window.scrollTo({ top: elPos, behavior: 'smooth' });
      }

      // Highlight the clicked link initially
      this.highlightSelectedLink();
      // Monitor scrolling to the link and prevent scrolling from taking over
      // link highligting until we're there
      this.checkForScrolling();
    }
  }

  handleOutlinePosition() {
    const outlineOffset = this._scrollTopOffset;
    const windowPos = window.pageYOffset;
    const pageHeading = document.querySelector('.pgheading');
    // This is getting the position of the Page heading element in order
    // to set the outline beside it - the minus 40 is giving some extra
    // room between the app header and the menu
    let pageHeadingPos =
      pageHeading.getBoundingClientRect().top + window.pageYOffset - 40;
    pageHeadingPos = Math.floor(pageHeadingPos);
    if (windowPos + (outlineOffset + this.headingOffset) >= pageHeadingPos) {
      this._wrapperEl.setAttribute('style', `top: ${outlineOffset + 40}px`);
      this._wrapperEl.classList.add('pageoutline--fixed');
    } else {
      this._wrapperEl.setAttribute(
        'style',
        `top: ${pageHeadingPos - outlineOffset + 40}px`,
      );
      this._wrapperEl.classList.remove('pageoutline--fixed');
    }
  }

  handleCurrentListItem() {
    this._isScrolling = true;
    if (this._isTargetingAnchor) {
      // If an anchor link was clicked, or an anchor url entered, ensure the given link is highlighted and don't change it because of scrolling
      this.highlightSelectedLink();
    } else {

      // Otherwise, check which heading has been scrolled below to set highlighting
      this._headerElsFormatted.forEach((el, i) => {
        const offset = this._scrollTopOffset;
        // Set each header elements position relative to the page
        let elPos = el.node.getBoundingClientRect().top + window.pageYOffset;
        elPos = Math.floor(elPos);
        // Set the next header position relative to the page, if no next element, set the elements
        // position as the bottom of the page (to keep the last outline item active/current)
        let nextElPos =
          this._headerElsFormatted[i + 1] !== undefined
            ? this._headerElsFormatted[i + 1].node.getBoundingClientRect().top +
              window.pageYOffset
            : this._targetEl.getBoundingClientRect().bottom + window.pageYOffset;
        nextElPos = Math.floor(nextElPos);
        const windowPos = window.pageYOffset;
        // Set outline link element with the identical data attribute as the ID on the header
        const linkEl = this._outputEl.querySelector(`[data-anchor="${el.id}"]`);
        // As you scroll, set class on the matching link element
        window.requestAnimationFrame(() => {
          if (windowPos >= elPos - offset && windowPos < nextElPos - offset) {
            linkEl.classList.add(this.currentLinkClass);
          } else {
            linkEl.classList.remove(this.currentLinkClass);
          }
        });
      });
    }
  }

  setupList() {
    let prevNode = 'H2';
    let prevUl,
        prevLi;

    // Create the Page outline unordered list element
    const elList = this.createListElement('ul', {
      'class': 'pageoutline__list',
    });

    // Loop through headers within the targetElSelector
    this._headerElsFormatted.forEach((el) => {
      // Check either H2 or H3 and then make new li and anchor elements along with correct attributes
      if (el.nodeName === 'H3') {
        const elLiChild = this.createListElement('li', {
          'class': 'pageoutline__listitem',
        });
        const elLiChildLink = this.createListElement('a', {
          'class': 'pageoutline__sublink',
          'href': `${window.location.pathname}#${el.id}`,
          'data-anchor': el.id,
        }, el.textContent);
        // Check prevNode in order to know whether to make the H3 element and the new ul element or just
        // another li in the current sequence
        if (prevNode !== 'H3') {
          const elUlChild = this.createListElement('li', {
            'class': 'pageoutline__list',
          });
          elLiChild.appendChild(elLiChildLink);
          elUlChild.appendChild(elLiChild);
          prevUl = elUlChild;
          prevLi.appendChild(elUlChild);
        } else {
          elLiChild.appendChild(elLiChildLink);
          prevUl.appendChild(elLiChild);
        }
      } else {
        const elLi = this.createListElement('li', {
          'class': 'pageoutline__listitem',
        });
        const elLiLink = this.createListElement('a', {
          'class': 'pageoutline__link',
          'href': `${window.location.pathname}#${el.id}`,
          'data-anchor': el.id,
        }, el.textContent);
        elLi.appendChild(elLiLink);
        prevLi = elLi;
        elList.appendChild(elLi);
      }
      prevNode = el.nodeName;
    })
    this._outputEl.appendChild(elList);
  }

  bindTriggers() {
    this._boundListClickHandler = this.handleClickOnList.bind(this);
    this._boundOutlinePositionHandler = this.handleOutlinePosition.bind(this);
    this._boundCurrentListItemHandler = this.handleCurrentListItem.bind(this);
    this._focusableEventEls = [];
    this._focusableEls = this._outputEl.querySelectorAll('[href]');
    this._focusableEls.forEach((focusableEl) => {
      focusableEl.addEventListener('click', this._boundListClickHandler);
      this._focusableEventEls.push(focusableEl);
    });

    // This probably should be in a setInterval vs on scroll, but even with the
    // interval being super quick, there was lag between the page scrolling and
    // the outline being set that was pretty ugly.

    // Throttle native scroll event if scroll.js util exists
    if (window.Intellum && window.Intellum.util && window.Intellum.util.scroll) {
      Intellum.util.scroll.addHandler(this._boundOutlinePositionHandler);
      Intellum.util.scroll.addHandler(this._boundCurrentListItemHandler);
    } else {
      window.addEventListener('scroll', (e) => {
        this._boundOutlinePositionHandler();
        this._boundCurrentListItemHandler();
      });
    }
  }

  setPositionOnLoad() {
    window.addEventListener('load', () => {
      // Sets initial outline position and checks if any list items are active
      this._boundOutlinePositionHandler();
      this._boundCurrentListItemHandler();
      // Check any hashes present; if so, move the window to the correct location
      if (window.location.hash) {
        const hashEl = document.querySelector(window.location.hash);
        const elPos =
          hashEl.getBoundingClientRect().top +
          (window.pageYOffset - this._scrollTopOffset);
        document.documentElement.scrollTop = elPos;
        this.checkForScrolling();
      }
    });
  }

  unbindAllEvents() {
    this._focusableEventEls.forEach((focusableEl) => {
      focusableEl.removeEventListener('blur', this._boundListClickHandler);
    });

    if (window.Intellum && window.Intellum.util && window.Intellum.util.scroll) {
      Intellum.util.scroll.removeHandler(this._boundOutlinePositionHandler);
      Intellum.util.scroll.removeHandler(this._boundCurrentListItemHandler);
    } else {
      window.removeEventListener('scroll', () => {
        this._boundOutlinePositionHandler();
        this._boundCurrentListItemHandler();
      });
    }
  }

  init() {
    const appHeader = document.querySelector('.appheader');

    this._scrollTopOffset =
      (appHeader ? appHeader.offsetHeight : 0) + this.headingOffset;
    this._wrapperEl = this.querySelector(this.wrapperElSelector);
    this._outputEl = this.querySelector(this.outputElSelector);
    this._targetEl = document.querySelector(this.targetElSelector);
    this._headerElsFormatted = [];

    // Find all headings within the .pgpost components
    if (this._targetEl) {
      this._headerEls = this._targetEl.querySelectorAll('.pgpost h2, .pgpost h3');
    }

    // Looking for a hash in the page url
    this._urlAnchor = window.location.hash.slice(1);

    // Checking is this is an embeded page
    this._isEmbeddedWidget = window.Cookies.get('is_exceed_embedded_widget') == 'true'

    // Flag to tell whether we are targeting an anchor based on url;
    // also gets turned on if an outline link is selected
    this._isTargetingAnchor = (this._urlAnchor.length) ? true : false;

    // Due to iframed content changing their Frame size on page load scrolling to content
    // might be broken on embedded widgets, reloading the anchor will fix this.
    if (this._isTargetingAnchor && this._isEmbeddedWidget) {
      setTimeout(() => { window.location.href = window.location.href; }, 500);
    }

    // Flag we use to see if a scroll event has fired
    this._isScrolling = false;

    // Used within loop to set whether we've come across an H2 heading element or not
    let handleH3asH2 = true;
    // Loop through found heading elements
    if (this._headerEls) {
      this._headerEls.forEach((headerEl, i) => {
        let headerElObj = {};
        // Add/Replace id attribute to each heading with punctuation stripped out and index
        // number added in case of headings with same text
        headerEl.setAttribute(
          'id',
          `${headerEl.textContent
            .trim()
            .replace(/[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g,'')
            .replace(/\s+/g, '-')
            .toLowerCase()}-${i}`,
        );
        // Build new object with heading data
        headerElObj = {
          'node': headerEl,
          'id': headerEl.id,
          'textContent': headerEl.textContent,
        };
        // Sets handleH3asH2 as false so we treat the next H3 correctly
        if (headerEl.nodeName === 'H2') {
          handleH3asH2 = false;
        }
        // If Pages have H3 elements before H2 elements, treat them as H2
        // elements until the first H2 element shows up
        if (headerEl.nodeName === 'H3' && handleH3asH2) {
          headerElObj.nodeName = 'H2';
        } else {
          headerElObj.nodeName = headerEl.nodeName;
        }
        // Push into new formatted array
        this._headerElsFormatted.push(headerElObj);
      });
    }
    // If we have found headings, build outline and triggers
    if (this._outputEl && !!this._headerElsFormatted.length) {
      this.setupList();
      this.bindTriggers();
      this.setPositionOnLoad();
      // Show the outline last so there is no snapping
      // into place as the page loads
      this._wrapperEl.classList.add('pageoutline--visible');
    }
  }

  connectedCallback() {
    super.connectedCallback();
    this.init();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.unbindAllEvents();
  }
}

customElements.define('exceed-pages-outline', ExceedPagesOutline);
