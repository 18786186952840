/*
Component: <exceed-filter-pagination>
Usage:  Part of the `exceed-filter-*` ecosystem. This component manages pagination but updating the url (via push state)
        and sending an event so that the filter-content component can update.
Notes:
*/

import { PolymerElement } from '@polymer/polymer';
import PubSub from 'pubsub-js';

import pubSubEvents from '../util/pubsub_event_channels';

class ExceedFilterPagination extends PolymerElement {

  static get is() {
    return 'exceed-filter-pagination';
  }

  static get properties() {
    return {
      paginationLinkSelector: {
        // the selector for the pagination links that we listen to
        type: String,
        value: 'a.pagination__link'
      },
      scrollToTop: {
        // if this is true we scroll to the top of the page when a pagination link is clicked
        type: Boolean,
        value: false
      }
    }
  }

  /**
   * Binds the pagination links and handles the actions accordingly
   * */
  bindPaginationLinks() {
    this.querySelectorAll(this.paginationLinkSelector).forEach((paginationLinkEl) => {
      paginationLinkEl.addEventListener('click', (event) => {
        event.preventDefault();

        // update the url
        let updateUrl = paginationLinkEl.getAttribute('href');
        history.pushState({ path: updateUrl }, '', updateUrl);

        // publish an event
        PubSub.publish(pubSubEvents.filters_update);

        // scroll to the top of the page
        if (this.scrollToTop) {
          window.scrollTo(0, 0, 'smooth');
        }
      });
    });
  }

  /**
   * INIT
   * */
  connectedCallback() {
    super.connectedCallback();
    this.bindPaginationLinks();
  }
}

customElements.define('exceed-filter-pagination', ExceedFilterPagination);