/*
Component: <exceed-password-strength>

Description:
Use this to display the strength of a new password as it is typed.

Scoring must start at 0; a max of 4 is assumed unless `maxScore` is passed. Text labels for each score are passed as a `data` attribute.

Example:
```
<exceed-password-strength class="passwordstrength" password-input-selector="#user_password" data-scores-text='{"0": "Very Weak", "1": "Weak", "2": "OK", "3": "Strong", "4": "Very Strong"}'>
```

External Dependencies:

- Requires the zxcvbn module (https://github.com/dropbox/zxcvbn) to be on the page on load.
- Note that zxcvbn is quite large so we load it as an external dependency as per recommendation
    - https://github.com/dropbox/zxcvbn#browserify--webpack
- MAKE SURE THAT zxcvbn IS LOADED ON THE PAGE

```
  <% content_for :extended_scripts_bottom do %>
    <%= javascript_include_tag 'zxcvbn/dist/zxcvbn.js' %>
  <% end %>
```
*/

import { PolymerElement } from '@polymer/polymer';

class ExceedPasswordStrength extends PolymerElement {
  static get is() {
    return 'exceed-password-strength';
  }

  static get properties() {
    return {
      passwordInputSelector: {
        type: String,
        value: '[type="password"]'
      },
      maxScore: {
        type: String,
        value: '4'
      }
    }
  }

  doMeterUpdate() {
    if (this.passwordInputEl.value.length) {
      let score = window.zxcvbn(this.passwordInputEl.value).score;
      // Divide up the meter based on max score - but 0 takes up a space, so add 1 to each side of the fraction
      this.passwordStrengthEl.dataset.score = (score + 1) / (parseInt(this.maxScore) + 1);
      this.passwordStrengthEl.innerText = this.scoresText[score] || '';
    } else {
      this.passwordStrengthEl.dataset.score = 0;
      this.passwordStrengthEl.innerText = '';
    }
  }

  addEventHandler() {
    this.passwordInputEl.addEventListener('keyup', (event) => {
      this.doMeterUpdate(event);
    });
  }

  addMeterHtml() {
    this.passwordStrengthEl = document.createElement('div');
    this.passwordStrengthEl.classList.add('passwordstrength__meter');
    this.appendChild(this.passwordStrengthEl);
  }

  initElement() {
    this.passwordInputEl = this.querySelector(this.passwordInputSelector);
    if (this.passwordInputEl && window.zxcvbn) {
      try {
        this.scoresText = JSON.parse(this.dataset.scoresText);
      }
      catch(error) {
        this.scoresText = {};
      }

      this.addMeterHtml();
      this.addEventHandler();
    }
  }

  connectedCallback() {
    super.connectedCallback();
    this.initElement();
  }
}

customElements.define('exceed-password-strength', ExceedPasswordStrength);
