/*
Component: <exceed-calculator>
Usage: Calculator

*/

import { PolymerElement } from '@polymer/polymer';

export default class ExceedCalculator extends PolymerElement {

  static get is() {
    return 'exceed-calculator';
  }

  static get properties() {
    return {
      outputElSelector: {
        // selector for the calculator
        type: String,
        value: '.calculator'
      },
    }
  }

  clear() {
    this._subRes.style.visibility = 'hidden';
    this._result.value = '0';
    this._subRes.value = '';
    this._calculations = [];
    this._newNumber = false;
    this._reset = false;
    this._lastOperator = null;
    this._currentResult = 0;
  }

  doMath(currentResult = null, value = null, operator = null) {
    switch(operator) {
      case 'plus':
        return currentResult + value;

      case 'minus':
        return currentResult - value;

      case 'multiply':
        return currentResult * value;

      case 'divide':
        return currentResult / value;
    }
  }

  returnOperator(operator = null) {
    switch(operator) {
      case 'plus':
        return '+';

      case 'minus':
        return '−';

      case 'multiply':
        return '×';

      case 'divide':
        return '÷';

      case 'equal':
        return '=';
    }
  }

  addNumber(e) {
    if (this._reset) this.clear();

    this._number = e.target.textContent;

    if (this._result.value === '0') this._result.value = this._number;
    else if (this._newNumber) this._result.value = this._number;
    else this._result.value += this._number;

    this._newNumber = false;
  }

  addOption(e) {
    this._option = e.target.dataset.option;
    this._lastChar = this._result.value[this._result.value.length - 1];

    if (this._option === 'dot') {
      (this._lastChar === '.' || this._result.value.indexOf('.') !== -1)
        ? this._result.value
        : this._result.value += '.';
    }

    else if (this._option === 'clearEntry') this._result.value = '0';

    else if (this._option === 'clear') this.clear();

    else if (this._option === 'reverse') this._result.value = this._result.value * -1;

    else if (this._option === 'undo') {

      (this._result.value.length === 1)
        ? this._result.value = '0'
        : this._result.value = this._result.value.substring(0, this._result.value.length - 1);
    }
  }

  calculate(e) {
    this._operator = e.target.dataset.operator;
    this._value = Number(this._result.value);
    this._subRes.style.visibility = 'visible';

    if (this._operator === 'pow') {
      this._subRes.value = ` sqr(${this._result.value})`;
      this._result.value = Math.pow(this._result.value, 2);
    }

    else if (this._operator === 'sqrt') {
      this._subRes.value = ` ${e.target.textContent}(${this._result.value})`;
      this._result.value = Math.sqrt(this._result.value);
    }

    else if (this._operator === 'fraction') {
      this._subRes.value = ` 1/(${this._result.value})`;
      this._result.value = 1 / this._result.value;
    }

    else if (this._operator === 'percent') this._result.value = parseFloat(((this._currentResult * this._value) / 100).toPrecision(14));

    else {

      if (this._operator === 'equal' && this._newNumber && this._lastOperator !== null && this._lastOperator !== 'equal'){

        if (this._calculations.length > 2)
        this._value = this._calculations.map(item => item).reverse().find(item => typeof item === 'number');

        this._calculations = [this._currentResult, this.returnOperator(this._lastOperator), this._value, this.returnOperator(this._operator)];

        this._currentResult = this.doMath(this._currentResult, this._value, this._lastOperator);
        this._currentResult = (parseFloat(this._currentResult.toPrecision(14)));

        this._result.value = this._currentResult;
        this._subRes.value = this._calculations.join(' ');

      } else {

        if (this._newNumber) {
          this._lastOperator = this._operator;
          this._calculations[this._calculations.length - 1] = this.returnOperator(this._operator);
          this._subRes.value = this._calculations.join(' ');
          this._reset = false;
          return;
        }

        (this._lastOperator === null)
          ? this._currentResult = this._value
          : this._currentResult = this.doMath(this._currentResult, this._value, this._lastOperator);

        (this._operator !== 'equal')
          ? this._lastOperator = this._operator
          : this._reset = true;

        this._newNumber = true;
        this._calculations.push(this._value);
        this._calculations.push(this.returnOperator(this._operator));

        this._currentResult = (parseFloat(this._currentResult.toPrecision(14)));
        this._result.value = this._currentResult;

        this._subRes.value = this._calculations.join(' ');
      }
    }
  }

  bindTriggers() {
    this._boundAddNumberHandler = this.addNumber.bind(this);
    this._boundAddOptionHandler = this.addOption.bind(this);
    this._boundCalculateHandler = this.calculate.bind(this);
    this._numbers.forEach(number => number.addEventListener('click', this._boundAddNumberHandler));
    this._options.forEach(option => option.addEventListener('click', this._boundAddOptionHandler));
    this._operators.forEach(operator => operator.addEventListener('click', this._boundCalculateHandler));
  }

  unbindAllEvents() {
    this._numbers.forEach(number => number.removeEventListener('click', this.addNumber));
    this._options.forEach(option => option.removeEventListener('click', this.addOption));
    this._operators.forEach(operator => operator.removeEventListener('click', this.calculate));
  }

  init() {
    this._outputEl = this.querySelector(this.outputElSelector);
    this._result = this.querySelector('.calculator__result-primary');
    this._subRes = this.querySelector('.calculator__result-secondary');
    this._numbers = [...this.querySelectorAll('[data-number]')];
    this._options = [...this.querySelectorAll('[data-option]')];
    this._operators = [...this.querySelectorAll('[data-operator]')];

    this._calculations = [];
    this._newNumber = false;
    this._reset = false;
    this._lastOperator = null;
    this._currentResult = 0;

    this.clear();

    if (this._outputEl) {
      this.bindTriggers();
    }
  }

  connectedCallback() {
    super.connectedCallback();
    this.init();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.unbindAllEvents();
  }
}

customElements.define('exceed-calculator', ExceedCalculator);
