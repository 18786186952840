//
// This file exports an instance of the BrowserNotifications class.
//
// This instance is meant to be used as the only interface to interact with
// browser notifications. The main reason to do so is to publish an event
// every time notifications permission is requested, so some components
// can update its contents depending on the new notifications permission state.
//

import PubSub from 'pubsub-js';
import pubSubEvents from './pubsub_event_channels';
import Push from 'push.js';

class BrowserNotifications {

  constructor() {
    this.push = Push;
  }

  publishChangeEvent() {
    PubSub.publish(pubSubEvents.notifications_change, {});
  }

  permission() {
    return this.push.Permission.get();
  }

  isSupported() {
    return this.push.supported();
  }

  requestPermission(onGranted, onDenied) {
    this.push.Permission.request(
      () => {
        this.publishChangeEvent();
        if (onGranted) { onGranted(); }
      },
      () => {
        this.publishChangeEvent();
        if (onDenied) { onDenied(); }
      }
    );
  }

  create(attrs) {
    var title = attrs.title;

    var opts = {
      tag: attrs.tag,
      body: attrs.body || "",
      icon: attrs.icon || "",
      requireInteraction: attrs.require_interaction || false
    }

    return new Notification(title, opts);
  }

}

export default new BrowserNotifications;
