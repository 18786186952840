/*
Component: <exceed-filter-tags>
Usage:  Part of the `exceed-filter-*` ecosystem. This component manages the input from a block of tags and
        throws an event. (`exceed-filter-collector` listens to and manages the events for multiple blocks)
Notes:
  The default behavior is to have a tag showing for each selected tag; each tag has a remove button
  to unselect the tag.
  Setting the is-tags-persistent property to true causes an alternate behavior:
  Any tag can be selected to add it to filtering, then deselected to remove it, but the tags themselves
  are always shown.
*/

import ExceedFilterBlock from './exceed-filter-block';
import queryString from 'query-string';
import PubSub from 'pubsub-js';
import pubSubEvents from '../util/pubsub_event_channels';
import axios from 'axios';

class ExceedFilterTags extends ExceedFilterBlock {

  static get is() {
    return 'exceed-filter-tags';
  }

  static get properties() {
    return {
      tagElSelector: {
        // selector for each tag in the block
        type: String,
        value: '.tag'
      },
      tagContainerElSelector: {
        // selector for element to be removed if tag is removed
        type: String,
        value: '.tag'
      },
      removeButtonSelector: {
        // selector for the button to remove each tag
        type: String,
        value: 'button'
      },
      isTagsPersistent: {
        // set true to toggle tag buttons instead of using tags with remove buttons
        type: Boolean,
        value: false
      },
      tagButtonSelector: {
        // selector for tagbutton when isTagsPersistent
        type: String,
        value: '.tagbutton'
      },
      tagButtonSelectedClass: {
        // class that marks a selected button when isTagsPersistent
        type: String,
        value: 'tagbutton--selected'
      },
      xhrUrl: {
        // url to update content of this component when needed
        type: String,
        value: ''
      }
    }
  }

  /**
   * Remove the filter for the selected tag (and the tag)
   * */
  removeFilter(event) {
    let tagContainer = event.target.closest(this.tagContainerElSelector);
    if (tagContainer) {
      tagContainer.parentNode.removeChild(tagContainer);
      this.setValues();
      this.sendUpdateEvent();
    }
  }

  toggleFilter(event) {
    let tagContainer = event.target.closest(this.tagContainerElSelector);
    if (tagContainer) {
      let tagButton = event.target.closest(this.tagButtonSelector);
      if (tagButton) {
        if (tagButton.classList.contains(this.tagButtonSelectedClass)) {
          tagButton.classList.remove(this.tagButtonSelectedClass);
          tagButton.setAttribute('aria-pressed', 'false');
        } else {
          tagButton.classList.add(this.tagButtonSelectedClass);
          tagButton.setAttribute('aria-pressed', 'true');
        }
      }
      this.setValues();
      this.sendUpdateEvent();
    }
  }

  /**
   * Sets the `filterValues` property on the element
   * */
  setValues() {
    this.filterValues = [];
    if (this.isTagsPersistent) {
      this.querySelectorAll(`.${this.tagButtonSelectedClass}`).forEach((tagButtonEl) => {
        let tagEl = tagButtonEl.closest(this.tagElSelector);
        this.filterValues.push(tagEl.dataset.value);
      });
    } else {
      this.querySelectorAll(this.tagElSelector).forEach((tagEl) => {
        this.filterValues.push(tagEl.dataset.value);
      });
    }
  }

  /**
   * Refresh tags on update from other filters
   * */
  refreshTags(isOnPageLoad) {
    if (!isOnPageLoad && this.xhrUrl.length && this.filterName) {
      let currentFilters = queryString.parse(location.search, {arrayFormat: 'bracket'});
      axios.get(this.xhrUrl, { params: currentFilters})
        .then((response) => {
          this.innerHTML = response.data;
          // Have to re-init the button clicks in the replaced content
          this.querySelectorAll(this.removeButtonSelector).forEach((buttonEl) => {
            buttonEl.addEventListener('click', this._boundClickHandler);
          });
        })
        .catch(() => {
        });
    }
  }

  /**
   * Sends an update event (so that the filter content and other elements can respond)
   * */
  sendUpdateEvent() {
    PubSub.publish(pubSubEvents.filters_tags, {
      filterName: this.filterName,
      filterValues: this.filterValues
    });
  }

  /**
   * Subscribe to updates
   * */
  bindUpdateSubscription() {
    PubSub.subscribe(pubSubEvents.filters_block, (msg, eventData) => {
      this.refreshTags(eventData.isOnPageLoad);
    });
  }

  /**
   * Binds a click event on all remove buttons in the block
   * */
  bindRemoveTagButtons() {
    this._boundClickHandler = this.removeFilter.bind(this);
    this.querySelectorAll(this.removeButtonSelector).forEach((buttonEl) => {
      buttonEl.addEventListener('click', this._boundClickHandler);
    });
  }

  /**
   * Binds a click event to persistent buttons
   * */
   bindPersistentButtons() {
    this._boundClickHandler = this.toggleFilter.bind(this);
    this.querySelectorAll(this.tagButtonSelector).forEach((buttonEl) => {
      buttonEl.addEventListener('click', this._boundClickHandler);
    });
  }

  initComponent() {
    this.bindUpdateSubscription();
    if (this.isTagsPersistent) {
      this.bindPersistentButtons();
    } else {
      this.bindRemoveTagButtons();
    }
    this.setValues();
  }

  connectedCallback() {
    super.connectedCallback();
  }

  disconnectedCallback() {
    let buttonEls = (this.isTagsPersistent) ? this.querySelectorAll(this.tagButtonSelector) : this.querySelectorAll(this.removeButtonSelector);
    buttonEls.forEach((buttonEl) => {
      buttonEl.removeEventListener('click', this._boundClickHandler);
    });
  }
}

customElements.define('exceed-filter-tags', ExceedFilterTags);
