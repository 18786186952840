/*
Component: <exceed-plan-activity-form>
Usage:
Notes: Very specific to the add to plan page

*/

import { PolymerElement } from '@polymer/polymer';
import axios from '../util/axios';
import serialize from 'form-serialize';

class ExceedPlanActivityForm extends PolymerElement {

  static get is() {
    return 'exceed-plan-activity-form';
  }

  static get properties() {
    return {
      parentDialogId: {
        // the dialog that this is attached to (for adding an activity)
        type: String,
        value: ''
      },
      isFormValid: {
        // an internal boolean that we can attach an observer to (for toggling the submit button)
        type: Boolean,
        value: false,
        observer: 'toggleSubmitButton'
      },
      confirmHeading: {
        // a string for the confirm dialog heading (when adding an activity)
        type: String,
        value: 'Warning'
      },
      editFormId: {
        // the id of the form that this custom element manages
        type: String,
        value: 'edit-activity-form'
      },
      confirmId: {
        // the id of the confirm panel (for adding an activity)
        type: String,
        value: ''
      },
      confirmMessageId: {
        // the id of the message that we show in the confirm panel
        // (we get the message from a server response and update the DOM)
        type: String,
        value: 'edit-activity-confirm-message'
      },
      selectedCourseIdId: {
        // for the auto-complete: the id of the hidden input that we add the selected course id to
        type: String,
        value: ''
      },
      defaultValuesAttribute: {
        // an attribute we can add to each element in the form to set a default (used when resetting the form)
        type: String,
        value: 'data-plan-form-default'
      },
      autoCompleteSelector: {
        // the selector for the autocomplete that we use (when adding an activity)
        type: String,
        value: 'exceed-searchable-select'
      },
      courseSelectionWarningId: {
        // an id for the element that we show when a user has NOT selected a course via autocomplete
        type: String,
        value: 'add-to-course-to-plan-warning'
      },
      generalErrorMessage: {
        // An error message to show if there's an error but with no message from the server
        type: String,
        value: "Could not add activity to plan"
      },
      cancelMessageId: {
        // The id of the cancel button for a message if it's not in a separate dialog
        type: String,
        value: "edit-activity-confirm-cancel"
      }
    }
  }

  /**
   * Toggles the submit button between disabled/enabled (to handle required fields)
   * */
  toggleSubmitButton(currentIsFormValidValue) {
    let submitButtonEl = this._submitButtonEl || this.querySelector(`#${this.editFormId} button[type="submit"]`);
    if (currentIsFormValidValue) {
      submitButtonEl.removeAttribute('disabled');
    } else {
      submitButtonEl.setAttribute('disabled', 'disabled');
    }
  }

  /**
   * Shows a warning message for auto-complete if an actual course has NOT been selected
   * */
  toggleCourseSelectionWarning(forceHide) {
    let courseWarningEl;
    if (!courseWarningEl) {
      courseWarningEl = this.querySelector(`#${this.courseSelectionWarningId}`);
    }
    if (courseWarningEl) {
      if (this._selectedCourseIdEl.value || forceHide) {
        courseWarningEl.style.display = 'none';
      } else {
        courseWarningEl.style.display = 'block';
      }
    }
  }

  /**
   * When adding an item to the plan we have an autocomplete field that we need to monitor and handle
   * */
  monitorAutoComplete() {
    this._autoCompleteEl.addEventListener('searchableselect.input', () => {
      this._selectedCourseIdEl.value = '';
    });
    this._autoCompleteInputEl.addEventListener('blur', () => {
      setTimeout(() => {
        this.toggleCourseSelectionWarning();
        this.checkForValidForm();
      }, 500);
    });
  }

  /**
   * Checks that we've got a value set for all required fields
   * */
  checkForValidForm() {
    let isAnEmptyRequiredField;
    let isCourseRequiredButNotSelected;

    if (this._selectedCourseIdEl && !this._selectedCourseIdEl.value) {
      isCourseRequiredButNotSelected = true;
    }

    // use "some" to find the first invalid form field
    isAnEmptyRequiredField = Array.from(this._requiredFieldEls).some((field) => {
      if (field.getAttribute('type') !== 'radio') {
        // for a standard input if there is no value in one of the required fields we are invalid
        return !field.value;
      } else {
        // for a radio button we check if any of the radio buttons with the same name are "checked"
        return !this._editFormEl.querySelectorAll(`input[name="${field.getAttribute('name')}"]:checked`).length;
      }
    });

    if (isCourseRequiredButNotSelected || isAnEmptyRequiredField) {
      this.isFormValid = false;
    } else {
      this.isFormValid = true;
    }
  }

  /**
   * Monitor any required fields so that we can enable the submit button only when the form is valid
   * */
  monitorRequiredFields() {
    this._requiredFieldEls.forEach((requiredFieldEl) => {
      this.checkForValidForm();

      let eventToBindTo = 'keyup';
      if (requiredFieldEl.getAttribute('type') === 'radio') {
        eventToBindTo = 'click';
      }

      requiredFieldEl.addEventListener(eventToBindTo, () => {
        this.checkForValidForm();
      });

    });
  }

  /**
   * Submits the form
   * - via AJAX if it's a remote form (for adding an activity)
   * - normally otherwise (when editing)
   * */
  submitForm(endpointPath) {
    if (this._editFormEl.dataset.remote) {
      let formData = serialize(this._editFormEl);

      axios.post(endpointPath, formData)
        .then(() => {
          location.reload();
        })
        .catch((error) => {
          if (error.response.data.status === 'exists') {
            this.toggleConfirmMessage(error.response.data.confirm_message_html);
          } else if (window.Intellum && Intellum.flashnotice) {
            Intellum.flashnotice.show(this.generalErrorMessage, 'warning');
          }
        })
    } else {
      this._editFormEl.submit();
    }
  }

  /**
   * Binds the submit button on the form
   * */
  bindEditFormSubmitButton() {
    this._submitButtonEl.addEventListener('click', (event) => {
      event.preventDefault();
      if (!this._submitButtonEl.hasAttribute('disabled')) {
        this.checkForValidForm();
        if (this.isFormValid) {
          this.submitForm(this._editFormEl.getAttribute('action'));
        }
      }
    });
  }

  /**
   * Bind the confirm submit button (to submit the actual form on confirmation)
   * */
  bindConfirmSubmitButton() {
    this._confirmButtonEl.addEventListener('click', (event) => {
      event.preventDefault();
      this.submitForm(this._confirmButtonEl.getAttribute('formaction'));
    });
  }

  /**
   * Bind the cancel message button to hide the message and restore the form;
   * Used if the message is not in a separate dialog
   * */
  bindCancelMessageButton() {
    this._cancelMessageEl.addEventListener('click', (event) => {
      this.toggleConfirmMessage(false);
    });
  }

  /**
   * Show the confirm message (when we're adding an activity that is already in the plan)
   * */
  toggleConfirmMessage(confirmMessage) {
    if (!this._confirmEl) { return; }
    let confirmMessageEl = this._confirmEl.querySelector(`#${this.confirmMessageId}`);

    if (confirmMessage) {
      confirmMessageEl.innerHTML = confirmMessage;
      this._editFormEl.style.display = 'none';
      this._confirmEl.style.display = 'block';
    } else {
      confirmMessageEl.innerHTML = '';
      this._editFormEl.style.display = 'block';
      this._confirmEl.style.display = 'none';
    }
  }

  /**
   * Resets the (add activity) form so that it can be used again
   * */
  clearForm() {
    this.toggleConfirmMessage(false);
    this.toggleCourseSelectionWarning(true);

    this._editFormEl.querySelectorAll(`[${this.defaultValuesAttribute}]`).forEach((fieldEl) => {
      fieldEl.value = fieldEl.getAttribute(this.defaultValuesAttribute);
    });
  }

  /**
   * Bind to actions
   * - monitor the auto-complete (for adding an activity)
   * - monitor the required fields
   * - handle the submit and confirm submit (confirm is only needed when adding an activity that is already in the plan)
   * */
  bindActions() {
    if (this._autoCompleteEl) {
      this.monitorAutoComplete();
    }
    this.monitorRequiredFields();
    this.bindEditFormSubmitButton();
    if (this._confirmEl) {
      this.bindConfirmSubmitButton();
    }
    if (this._cancelMessageEl) {
      this.bindCancelMessageButton();
    }
  }

  /**
   * Set up all the elements that we're going to use in this form manager
   * */
  initElements() {
    this._editFormEl = this.querySelector(`#${this.editFormId}`);
    this._submitButtonEl = this._editFormEl.querySelector('button[type="submit"]');
    if (this.confirmId) {
      this._confirmEl = this.querySelector(`#${this.confirmId}`);
    }
    if (this._confirmEl) {
      this._confirmButtonEl = this._confirmEl.querySelector('button[type="submit"]');
    }
    this._autoCompleteEl = this.querySelector(this.autoCompleteSelector);
    if (this._autoCompleteEl) {
      this._autoCompleteInputEl = this._autoCompleteEl.querySelector('input');
    }
    if (this.selectedCourseIdId) {
      this._selectedCourseIdEl = this.querySelector(`#${this.selectedCourseIdId}`);
    }
    this._requiredFieldEls = this.querySelectorAll('input[required]');

    if (this.parentDialogId) {
      this._dialogEl = document.getElementById(this.parentDialogId);
    }
    this._cancelMessageEl = this.querySelector(`#${this.cancelMessageId}`);
    this._editFormEl.querySelectorAll(`[${this.defaultValuesAttribute}]`).forEach((fieldEl) => {
      fieldEl.value = fieldEl.getAttribute(this.defaultValuesAttribute);
    });
  }

  init() {
    this.initElements();

    // if we're attached to a dialog (as when adding an item) we need to do all the things every time
    // the dialog is opened.
    if (this._dialogEl) {
      this._dialogEl.addEventListener('show.dialog', () => {
        this.clearForm();
        this.bindActions();
      });
    } else {
      // otherwise (for editing) we get a new instance of this element every time so we can just bind the actions.
      this.bindActions();
    }
  }

  connectedCallback() {
    super.connectedCallback();
    this.init();
  }
}

customElements.define('exceed-plan-activity-form', ExceedPlanActivityForm);
