/**
 * Component: <exceed-request-trigger-on-action>
 * Usage: triggers a certain requests after actioning an element
 * Notes:
 * - Requires an action triggerer with the class '.button', but this can be overwritten
 * - Requires an target url where the request should be fired to
 * - events and methods can be passed
 * */

import { PolymerElement } from '@polymer/polymer';
import axios from '../util/axios';

class ExceedRequestTriggerOnAction extends PolymerElement {

  static get is() {
    return 'exceed-request-trigger-on-action';
  }

  static get properties() {
    return {
      actionElementSelector: {
        // The selector for the elements that we listen for a the given event
        type: String,
        value: '.button'
      },
      targetUrl: {
        // the url to trigger the request to
        type: String,
        value: ''
      },
      event: {
        // the event we want to listen to
        type: String,
        value: 'click'
      },
      method: {
        // http method
        type: String,
        value: 'post'
      },
      doTrigger: {
        // when false, do not trigger any request
        type: Boolean,
        value: false
      }
    }
  }

  triggerAction(){
    axios[this.method](this.targetUrl,{});
  }

  init() {
    this._actionElement = this.querySelector(this.actionElementSelector);
    if (this.targetUrl && this._actionElement) {
      this._actionElement.addEventListener(this.event, (event) => {
        if (this.doTrigger){
          this.triggerAction();
          this.doTrigger = false;
        }
      });
    }
  }

  connectedCallback() {
    super.connectedCallback();
    this.init();
  }

}

customElements.define('exceed-request-trigger-on-action', ExceedRequestTriggerOnAction);
