/*
Component: <exceed-countdown-clock>

This outputs text telling how far away a target time is, in days, hours, minutes, and seconds. It generates a more specific countdown than `exceed-countdown-text`.
*/

import { PolymerElement } from '@polymer/polymer';

class ExceedCountdownClock extends PolymerElement {

  static get is() {
    return 'exceed-countdown-clock';
  }

  static get properties() {
    return {
      targetDateTimeForUser: {
        // date and time of the thing we're counting down to in the user's time zone, with tz offset - required
        // Example: "2020-05-08T22:30:00-0700"
        type: String,
        value: ""
      },
      userTimezoneOffsetForNow: {
        // Timezone offset for user's timezone now in minutes
        type: Number,
        value: new Date().getTimezoneOffset()
      },
      userTimezoneOffsetForTarget: {
        // Timezone offset for user's timezone at target event time in minutes
        type: Number,
        value: new Date().getTimezoneOffset()
      },
      timerInterval: {
        // in seconds
        type: Number,
        value: 1
      },
      isReloadedOnCountdownEnd: {
        type: Boolean,
        value: false
      },
      isUnitNumbersPadded: {
        // if true, add leading zero to any 1-digit unit number
        type: Boolean,
        value: false
      },
      wrapperSelector: {
        // class for the wrapper of the content within the element -
        //   separates the element function from the presentation
        type: String,
        value: '.countdownclock'
      },
      visibleClass: {
        // class to be added to the wrapper to make it visible
        type: String,
        value: 'countdownclock--visible'
      },
      daysUnitTextSelector: {
        // selector for the element that contains all info for days
        type: String,
        value: '[data-unit="days"] .countdownclock__unitcount'
      },
      hoursUnitTextSelector: {
        // selector for the element that contains all info for hours
        type: String,
        value: '[data-unit="hours"] .countdownclock__unitcount'
      },
      minutesUnitTextSelector: {
        // selector for the element that contains all info for minutes
        type: String,
        value: '[data-unit="minutes"] .countdownclock__unitcount'
      },
      secondsUnitTextSelector: {
        // selector for the element that contains all info for seconds
        type: String,
        value: '[data-unit="seconds"] .countdownclock__unitcount'
      },
    }
  }

  toggleVisible(shouldShow = false) {
    (shouldShow) ? this._wrapperEl.classList.add(this.visibleClass) : this._wrapperEl.classList.remove(this.visibleClass);
  }

  updateContent(timeDifference = this.getTimeDifference(this._targetDateTime)) {
    if (timeDifference > 0) {
      const timeUnitValuesArr = this.getTimeUnitsFromMs(timeDifference);
      for (let i = 0; i < 4; i++) {
        // Update text for unit unless it isn't changing
        if (this._timeUnitTextEls[i] && this._timeUnitTextEls[i].textContent != timeUnitValuesArr[i]) {
          this._timeUnitTextEls[i].textContent = timeUnitValuesArr[i];
        }
      }
    } else {
      this.stopTimer();
      this.toggleVisible(false);
      if (this.isReloadedOnCountdownEnd) {
        location.reload();
      }
    }
  }

  getTimeUnitsFromMs(ms, isPadded = this.isUnitNumbersPadded) {
    const seconds = Math.floor(ms / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    let unitsArr = [
      days.toString(),
      (hours % 24).toString(),
      (minutes % 60).toString(),
      (seconds % 60).toString()
    ];
    if (isPadded) {
      unitsArr.forEach((unitText, i) => {
        unitsArr.splice(i, 1, unitText.padStart(2, '0'));
      });
    }
    return unitsArr;
  }

  getTimeDifference(targetDateTime = new Date(), baseDateTime = new Date()) {
    let diff = targetDateTime.getTime() - baseDateTime.getTime();
    return (diff >= 0) ? diff : 0;
  }

  setTimer() {
    if (this.timerInterval > 0) {
      this._timer = window.setInterval(() => {this.updateContent()}, this.timerInterval * 1000);
    }
  }

  stopTimer() {
    window.clearInterval(this._timer);
  }

  initElement() {
    if (this.targetDateTimeForUser.length) {
      this._wrapperEl = this.querySelector(this.wrapperSelector) || this;

      this._daysUnitTextEl = this.querySelector(this.daysUnitTextSelector);
      this._hoursUnitTextEl = this.querySelector(this.hoursUnitTextSelector);
      this._minutesUnitTextEl = this.querySelector(this.minutesUnitTextSelector);
      this._secondsUnitTextEl = this.querySelector(this.secondsUnitTextSelector);

      if (this._daysUnitTextEl || this._hoursUnitTextEl || this._minutesUnitTextEl || this._secondsUnitTextEl) {
        this._targetDateTime = new Date(this.targetDateTimeForUser);
        this._timeUnitTextEls = [this._daysUnitTextEl, this._hoursUnitTextEl, this._minutesUnitTextEl, this._secondsUnitTextEl]
        let initialTimeDifference = this.getTimeDifference(this._targetDateTime);
        if (initialTimeDifference > 0) {
          this.updateContent(initialTimeDifference);
          this.setTimer();
          this.toggleVisible(true);
        }
      }
    }
  }

  connectedCallback() {
    super.connectedCallback();
    this.initElement();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.stopTimer();
  }
}

customElements.define('exceed-countdown-clock', ExceedCountdownClock);
