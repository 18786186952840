/*
Component: <exceed-forum-updates-subscribe>
Usage: This component is used to subscribe to forum updates.
       When a new ForumPost is created on a forum, then a message is published
       through that Forum specific channel.
       When the message is received it triggers a call to the forums `checkForUpdates`
       function, which notifies the user that the contents are out of date.
*/

import centrifugo from '../util/centrifugo';
import { PolymerElement } from '@polymer/polymer';

export default class ExceedForumUpdatesSubscribe extends PolymerElement {

  static get is() {
    return 'exceed-forum-updates-subscribe';
  }

  static get properties() {
    return {
      // Centrifugo channel name to subscribe to
      channel: {
        type: String
      },
      forumElId: {
        type: String
      }
    }
  }

  getForumEl() {
    if (window.Intellum && window.Intellum.forums) {
      return window.Intellum.forums[this.forumElId];
    }
  }

  handleMessage(message) {
    this._forumEl.checkForUpdates();
  }

  init() {
    if (this.centrifugo && !this._subscription) {
      this._forumEl = this.getForumEl()

      if (this._forumEl) {
        this._subscription = this.centrifugo.subscribe(this.channel, this.handleMessage.bind(this));
      }
    }
  }

  connectedCallback() {
    super.connectedCallback();
    this.centrifugo = centrifugo;
    this.init();
  }

}

customElements.define('exceed-forum-updates-subscribe', ExceedForumUpdatesSubscribe);
